import {Button, Col, Form, message, Modal, Popconfirm, Row, Select, Space, Table} from "antd";
import dayjs from "dayjs";
import React, {useEffect, useRef, useState} from "react";
import {packageService} from "../../service/package";
import {ImportOutlined} from "@ant-design/icons";
import {PackageItemForm} from "./PackageItemForm";

export const PackageItemList = () => {

    const [form] = Form.useForm();
    const [msgApi, contextHolder] = message.useMessage();

    const firstRender = useRef(true);

    const [packageGroupList, setPackageGroupList] = useState<any[]>();
    const [selectGroupId, setSelectGroupId] = useState<number | undefined>(undefined);
    // 分页参数
    const [pageNum, setPageNum] = useState(1);
    // 分页参数
    const [pageSize, setPageSize] = useState(10);
    // 总数
    const [total, setTotal] = useState(0);
    // 套餐列表
    const [packageItemList, setPackageItemList] = useState<any[]>([]);
    // 当前选中的套餐
    const [packageItem, setPackageItem] = useState<any>();
    // 是否显示编辑、新增套餐的弹窗
    const [showFormModal, setShowFormModal] = useState(false)

    useEffect(() => {
        init();
    }, []);


    /**
     * 当下拉框数据发生变化时，重新设置选中的套餐组id，默认选中第一个
     */
    useEffect(() => {
        if (firstRender.current) {
            return;
        }
        // console.log("packageGroupList", packageGroupList);
        setSelectGroupId(packageGroupList?.[0]?.id);
        form.setFieldValue("packageGroupId", packageGroupList?.[0]?.id);
    }, [form, packageGroupList])


    useEffect(() => {
        if (firstRender.current) {
            return;
        }
        // console.log("selectGroupId", selectGroupId);
        search();
    }, [selectGroupId]);

    const init = () => {
        packageService.enablePackageGroupList()
            .then(res => {
                // console.log("groupListRes", res);
                const responseMsg = res.data;
                if (responseMsg.status == 0) {
                    setPackageGroupList(responseMsg.data);
                    // 首次请求完成后，标记非首次渲染
                    firstRender.current = false;
                } else {
                    msgApi.error(responseMsg.msg).then();
                }
            })
            .catch(err => {
                // console.log("err", err);
                msgApi.error("网页发生错误，请联系管理员").then();
            })
    }


    const onGroupSelectChange = (value: number, option: any) => {
        // console.log("输入框的值发生改变", value);
        setSelectGroupId(value);
    }


    const onFormSubmitSuccess = () => {
        setPackageItem(undefined);
        setShowFormModal(false);
        search();
    }
    const search = () => {
        packageService.listPackageItem({
            pageNum: pageNum - 1,
            pageSize: pageSize,
            packageGroupId: selectGroupId
        })
            .then(res => {
                // console.log("查询套餐列表", res.data);
                const responseMsg = res.data;
                if (responseMsg.status == 0) {
                    setPackageItemList(responseMsg.data.content);
                    setTotal(responseMsg.data.totalElements);
                } else {
                    msgApi.error(responseMsg.msg).then();
                }
            })
            .catch(err => {
                // console.log("err", err);
                msgApi.error("网页发生错误，请联系管理员").then();
            });
    }

    const resetValues = () => {
        form.resetFields();
        // console.log("onReset");
        // setSelectedBatchId(undefined);
    }

    /**
     * 编辑套餐
     * @param packageItem
     */
    const editPackageItem = (packageItem: any) => {
        setPackageItem(packageItem);
        setShowFormModal(true);
    }

    const deletePackageItem = (packageItem: any) => {
        // msgApi.info("暂不支持删除套餐").then();
        packageService.deletePackageItem({id: packageItem.id})
            .then(res => {
                const responseMsg = res.data;
                if (responseMsg.status == 0) {
                    msgApi.success("删除套餐成功").then();
                    search();
                } else {
                    msgApi.error(responseMsg.msg).then();
                }
            })
            .catch(err => {
                // console.log("err", err);
                msgApi.error("网页发生错误，请联系管理员").then();
                search();
            })
    }

    const formClose = () => {
        setPackageItem(undefined);
        setShowFormModal(false);
    }


    const columns = [
        {
            key: "id",
            dataIndex: "id",
            title: "套餐ID",
        },
        {
            key: "packageName",
            dataIndex: "packageName",
            title: "套餐名称",
        },
        {
            key: "packagePrice",
            dataIndex: "packagePrice",
            title: "套餐价格",
        },
        {
            key: "packagePayPrice",
            dataIndex: "packagePayPrice",
            title: "套餐实际价格",
        },
        {
            key: "packageGroupId",
            dataIndex: "packageGroupId",
            title: "所属套餐组",
            render: (text: any, record: any) => {
                return packageGroupList?.find((item) => item.id == text)?.title
            }
        },
        {
            key: "packageGroupId",
            dataIndex: "packageGroupId",
            title: "套餐组类型",
            render: (text: any, record: any) => {
                const groupType = packageGroupList?.find((item) => item.id === text)?.packageGroupType;
                if (groupType === "gift") {
                    return "免费套餐"
                } else if (groupType === "buy") {
                    return "付费套餐"
                } else if (groupType === "manual") {
                    return "手动赠送的套餐"
                } else {
                    return "未知类型套餐";
                }
            }
        },
        {
            key: "packageStatus",
            dataIndex: "packageStatus",
            title: "套餐状态",
            render: (text: any, record: any) => {
                if (text === "enable") {
                    return <span style={{color: "green"}}>启用</span>
                } else if (text === "disable") {
                    return <span style={{color: "gray"}}>禁用</span>
                } else {
                    return <span style={{color: "red"}}>未知</span>
                }
            }
        },
        {
            key: "createTime",
            dataIndex: "createTime",
            title: "创建时间",
            render: (text: any) => {
                if (text) {
                    return dayjs.unix(text).format('YYYY-MM-DD HH:mm:ss')
                }
                return text
            }
        },
        {
            key: "updateTime",
            dataIndex: "updateTime",
            title: "更新时间",
            render: (text: any) => {
                if (text) {
                    return dayjs.unix(text).format('YYYY-MM-DD HH:mm:ss')
                }
                return text
            }
        },
        {
            key: "action",
            dataIndex: "action",
            title: "操作",
            render: (text: any, record: any) => {
                return <Space size={"middle"}>
                    <Button type={"link"} onClick={() => editPackageItem(record)}>编辑</Button>
                    <Popconfirm title={"确定删除该套餐吗？"} okText={"确定"} cancelText={"取消"}
                                onConfirm={() => deletePackageItem(record)}>
                        <Button type={"link"}>删除</Button>
                    </Popconfirm>
                </Space>
            }
        }
    ];


    return <div>
        {contextHolder}
        <Form form={form} onFinish={() => {
        }}>
            <Row>
                <Col span={8}>
                    <Form.Item name={"packageGroupId"}
                               label={"所属套餐组"}
                               labelCol={{span: 8}}
                               wrapperCol={{span: 16}}
                        // initialValue={packageGroupList?.[0]?.id}
                    >

                        <Select
                            options={packageGroupList?.map((item: any) => {
                                return {label: item.title, value: item.id}
                            })}
                            placeholder={"请选择套餐组"}
                            onChange={onGroupSelectChange}
                        />
                    </Form.Item>
                </Col>
                <Col span={1}></Col>
                <Col span={6}>
                    <Space size={'large'}>
                        <Button type={'primary'} onClick={search} loading={false}>查询</Button>
                        {/*<Button type={'default'} onClick={resetValues}>重置</Button>*/}
                    </Space>
                </Col>
            </Row>
            <Row>
                <Col span={21}></Col>
                <Col span={3} style={{textAlign: 'right'}}>
                    <Form.Item>
                        <Space size={'small'}>
                            <Button onClick={() => {
                                setShowFormModal(true)
                            }} icon={<ImportOutlined/>}>新增套餐</Button>
                        </Space>
                    </Form.Item>
                </Col>
            </Row>
        </Form>
        <Table
            rowKey={"id"}
            columns={columns}
            dataSource={packageItemList}
            pagination={{
                position: ["bottomRight"],
                pageSize: pageSize,
                current: pageNum,
                total: total,
                onChange: (page, pageSize) => {
                    setPageNum(page);
                    setPageSize(pageSize || 10);
                }
            }}
        />
        <Modal open={showFormModal}
               closable={true}
               footer={null}
               destroyOnClose={true}
               maskClosable={false}
               onCancel={formClose}>
            <PackageItemForm
                packageGroupList={packageGroupList}
                defaultPackageGroupId={selectGroupId}
                packageItem={packageItem}
                onSuccess={onFormSubmitSuccess}/>
        </Modal>
    </div>
}


