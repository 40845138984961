import React, {useEffect, useState} from "react";
import {Outlet, useLocation, useNavigate} from "react-router-dom";
import {Avatar, Dropdown, Layout, Menu, MenuProps, Spin} from "antd";
import {useAuth} from "../../context";
import {getIcon} from "../../components";
import './index.css';
import {defaultMenus} from "./menu";
import {authService} from "../../service";

const {Header, Content, Footer, Sider} = Layout;

type MenuItem = Required<MenuProps>['items'][number];


function getItem(
    label: React.ReactNode,
    key: React.Key,
    icon?: React.ReactNode,
    children?: MenuItem[] | null,
    path?: string
): MenuItem {
    return {
        key,
        icon,
        children,
        label,
        path,
    } as MenuItem;
}


export const Home = (props: any) => {

    // const auth = useAuth();
    const navigate = useNavigate();
    const location = useLocation()

    const [menus, setMenus] = useState<any[]>([])
    const [openKeys, setOpenKeys] = useState<string[]>([])
    const [isLoading, setIsLoading] = useState(true)
    // 菜单收起
    const [collapsed, setCollapsed] = useState(false);

    const dropDownItems: MenuItem[] = [
        // getItem(<div onClick={onPwdChange}>修改密码</div>, 'passwordChange'),
        // getItem(<div onClick={signOut}>退出登录</div>, 'data')
    ];

    useEffect(() => {
        setMenus(getMenuItems(defaultMenus));
        authService.checkLogin().then(res=> {
            if(res.data.status === 0)
                setIsLoading(false);
        });
        // navigate("/device/list");
        // setOpenKeys(["/device","/device/list"])
    }, []);

    const getMenuItems = (menus: any[]) => {
        const items: any[] = []
        menus.forEach((item, index) => {
            items.push(getItem(
                item.menuName,
                item.url,
                item.icon ? getIcon(item.icon) : null,
                null,
                item.menuType === 'menu' ? undefined : item.url
            ))
            if (item.childList && item.childList.length) {
                items[index].children = getMenuItems(item.childList)
            }
        })
        // console.log("菜单：", items);
        return items
    }

    const onOpenChange = (newOpenKeys: string[]) => {
        setOpenKeys(newOpenKeys)
    }

    const menuSelected = (e: any) => {
        // todo 跳转到指定的路由界面
        // console.log(e)
        // console.log(e.item.props.path)
        if (e.item.props.path) {
            navigate(e.item.props.path);
            sessionStorage.setItem('path', e.item.props.path)
        } else {
            navigate("/");
        }
    }

    const renderLoading = () => {
        return <Layout style={{minHeight: '100vh', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
            <Spin spinning={true} tip="加载中..." size="large">
            </Spin>
        </Layout>;
    }

    const renderContent = () => {
        return <Layout style={{minHeight: '100vh'}}>

            <Sider collapsible collapsed={collapsed} onCollapse={value => setCollapsed(value)}>
                <div className={'logo'}>
                    {/*<img src={logo} style={{ width: "40px", height: "40px" }} alt={'logo'} />*/}
                    <div className={'test1111'}>
                        设备管理平台
                    </div>
                </div>
                <Menu
                    theme="dark"
                    defaultSelectedKeys={['/device/list']}
                    mode="inline"
                    selectedKeys={[location.pathname]}
                    openKeys={openKeys}
                    items={menus}
                    onSelect={menuSelected}
                    onOpenChange={onOpenChange}
                />
            </Sider>
            <Layout className="site-layout">
                <Header className="site-layout-background"
                        style={{
                            padding: "0 15px 0 0",
                            display: 'flex',
                            flexDirection: "row-reverse",
                            alignItems: 'center'
                        }}>
                    <Dropdown menu={{items: dropDownItems}}>
                        <Avatar alt={'管理员'} size={35}/>
                    </Dropdown>
                </Header>
                <Content style={{margin: '20px'}}>
                    {/*<Breadcrumb style={{margin: '16px 0'}}>*/}
                    {/*    <Breadcrumb.Item>User</Breadcrumb.Item>*/}
                    {/*    <Breadcrumb.Item>Bill</Breadcrumb.Item>*/}
                    {/*</Breadcrumb>*/}
                    <div className="site-layout-background" style={{height: "100%", width: "100%"}}>
                        <Outlet/>
                    </div>
                </Content>
                <Footer style={{textAlign: 'center'}}>设备管理平台 ©2023 </Footer>
            </Layout>
            {/*<Modal*/}
            {/*    title={'修改密码'}*/}
            {/*    open={pwdVisible}*/}
            {/*    onCancel={onPwdChangeCancel}*/}
            {/*    onOk={onPwdChangeOk}*/}
            {/*>*/}
            {/*    <Form form={form}>*/}
            {/*        <Form.Item label={"旧密码"} labelCol={{ span: 5 }} wrapperCol={{ span: 19 }}*/}
            {/*                   name={"oldPwd"} initialValue={null}*/}
            {/*                   rules={[{ required: true, message: '请输入旧密码' }]}>*/}
            {/*            <Input.Password placeholder='请输入' />*/}
            {/*        </Form.Item>*/}
            {/*        <Form.Item label={"新密码"} labelCol={{ span: 5 }} wrapperCol={{ span: 19 }}*/}
            {/*                   name={"newPwd"} initialValue={null}*/}
            {/*                   rules={[{ required: true, message: '请输入新密码' }]}>*/}
            {/*            <Input.Password placeholder='请输入' />*/}
            {/*        </Form.Item>*/}
            {/*        <Form.Item label={"确定密码"} labelCol={{ span: 5 }} wrapperCol={{ span: 19 }}*/}
            {/*                   name={"confirmPwd"} initialValue={null}*/}
            {/*                   rules={[{ required: true, message: '请输入确定密码' }]}>*/}
            {/*            <Input.Password placeholder='请输入' />*/}
            {/*        </Form.Item>*/}
            {/*    </Form>*/}
            {/*</Modal>*/}
        </Layout>
    }

    return <>
        {
            isLoading ? renderLoading() : renderContent()
        }
    </>

}
