import {Column} from "@ant-design/charts";
import {useEffect, useState} from "react";
import {orderService} from "../../service";

export const $7DaysProductColumn = () => {
    const [loading, setLoading] = useState(true)
    const [data, setData] = useState([])

    const config = {
        xField: 'order_date',
        yField: 'total_pay_price',
        colorField: 'product_name',
        group: true,
        style: {
            // 矩形四个方向的内边距
            inset: 5,
            // 矩形单个方向的内边距
            // insetLeft:5,
            // insetRight:20,
            // insetBottom:10
            // insetTop:10
        },
    };


    useEffect(() => {
        orderService.$7DaysProductData().then((res) => {
            // console.log("res", res)
            setData(res.data.data)
            setLoading(false)
        })
    }, []);

    return <div>
        <div className={'title'}>
            <span>7日套餐购买柱状图</span>
        </div>
        <Column {...config} data={data} loading={loading}></Column>
    </div>
}