import {Button, Form, Input, message, Select} from "antd";
import {useState} from "react";
import {packageService} from "../../service/package";


export interface IPackageGroupFormProps {
    onSuccess?: (packageGroup: any) => void
}

export const PackageGroupForm = (props: IPackageGroupFormProps) => {
    const {onSuccess} = props;
    const [msgApi, contextHolder] = message.useMessage()
    const [form] = Form.useForm();

    const [submitting, setSubmitting] = useState(false);

    const onFinish = (values: any) => {
        // console.log('form submit:', values);
        setSubmitting(true);
        packageService.createPackageGroup(values)
            .then(res => {
                const responseMsg = res.data;
                if (responseMsg.status == 0) {
                    // console.log("新增套餐组成功", responseMsg.data);
                    onSuccess && onSuccess(responseMsg.data);
                    // form.resetFields();
                    // setSubmitting(false);
                } else {
                    // console.log("新增套餐组失败");
                    msgApi.error(responseMsg.msg).then(() => setSubmitting(false));
                }
            })
            .catch(err => {
                // console.log("err", err);
                msgApi.error("网页发生错误，请联系管理员").then(() => setSubmitting(false));
            })
    }


    return (
        <div style={{
            border: "0px solid red",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            padding: "20px 30px 0 0"
        }}>
            {contextHolder}
            <Form form={form}
                  labelCol={{span: 8}}
                  wrapperCol={{span: 16}}
                  autoComplete="off"
                  style={{width: "800px", border: "0px solid blue"}}
                  onFinish={onFinish}
            >
                <Form.Item
                    name={"title"}
                    label={"套餐组名称"}
                    rules={[{required: true, message: '套餐组名称不能为空!'}]}
                >
                    <Input/>
                </Form.Item>

                <Form.Item
                    name={"packageGroupType"}
                    label={"套餐组类型"}
                    rules={[{required: true, message: '套餐组类型不能为空!'}]}
                >
                    <Select>
                        <Select.Option value={"gift"}>免费套餐</Select.Option>
                        <Select.Option value={"buy"}>付费套餐</Select.Option>
                    </Select>
                </Form.Item>

                <Form.Item
                    name={"description"}
                    label={"套餐组描述"}
                    rules={[{required: true, message: '套餐组描述不能为空!'}]}
                >
                    <Input.TextArea/>
                </Form.Item>

                <Form.Item wrapperCol={{offset: 8, span: 16}}>
                    <Button type="primary" htmlType="submit" loading={submitting}>
                        新增套餐
                    </Button>
                </Form.Item>
            </Form>
        </div>
    )
}
