import {httpService} from "../http-service";

export class WxConfigService {
    public getWxList() {
        const url = `wx/config/list`;
        return httpService.post(url, {});
    }

    public addOrUpdate(mch: any) {
        if (mch && mch.id) {
            return this._updateMch(mch);
        } else {
            return this._addMch(mch)
        }
    }

    _addMch(mch: any) {
        return httpService.post("wx/config/add", mch);
    }

    _updateMch(mch: any) {
        return httpService.post("wx/config/update", mch);
    }

    public enableMch(mch: any) {
        return httpService.post('wx/config/enable', mch);
    }
}

export const wxConfigService = new WxConfigService();
