import {Button, Col, message, Modal, Popconfirm, Row, Space, Table, Tooltip} from "antd";
import React, {useEffect, useState} from "react";
import dayjs from "dayjs";
import {wxConfigService} from "../../service";
import {PackageGroupForm} from "../package/PackageGroupForm";
import {WxMchForm} from "./WxMchForm";
import {QuestionCircleOutlined} from "@ant-design/icons";

export const WxMchList = () => {

    const [msgApi, messageCtxHolder] = message.useMessage()

    const [showMchForm, setShowMchForm] = useState(false);

    const [selectedMch, setSelectedMch] = useState<any>(null);

    const columns = [
        {
            key: "mchId",
            dataIndex: "mchId",
            title: "商户号"
        },
        {
            key: "mchName",
            dataIndex: "mchName",
            title: "商户名称",
        },
        {
            key: "status",
            dataIndex: "status",
            title: () => {
                return <div>
                    <Tooltip title={"只能有一项生效"}>
                        状态 <QuestionCircleOutlined/>
                    </Tooltip>
                </div>
            },
            render: (text: string) => {
                switch (text) {
                    case "enable":
                        return <span style={{color: "green"}}>生效中</span>
                    case "disable":
                        return <span style={{color: "gray"}}>未生效</span>
                    default:
                        return <span style={{color: "red"}}>未知</span>
                }
            }
        },
        {
            key: "description",
            dataIndex: "description",
            title: "商户描述",
        },
        {
            key: "createTime",
            dataIndex: "createTime",
            title: "创建时间",
            render: (text: any) => {
                if (text) {
                    return dayjs.unix(text).format('YYYY-MM-DD HH:mm:ss')
                }
                return text
            }
        },
        {
            key: "updateTime",
            dataIndex: "updateTime",
            title: "更新时间",
            render: (text: any) => {
                if (text) {
                    return dayjs.unix(text).format('YYYY-MM-DD HH:mm:ss')
                }
                return text
            }
        },
        {
            key: 'operate',
            dataIndex: 'operate',
            title: '操作',
            render: (text: any, record: any) => {
                return <Space size={'small'}>
                    <Button type={'link'} size={"small"} onClick={() => {
                        setSelectedMch(record);
                        setShowMchForm(true);
                    }}>编辑</Button>
                    {/*<Button type={'link'} danger={true} size={"small"} onClick={() => {*/}
                    {/*    setSelectedMch(record);*/}
                    {/*}}>删除</Button>*/}

                    <Popconfirm title={"确定启用此商户号吗？"} disabled={record.status === 'enable'} okText={"确定"} cancelText={"取消"}
                                onConfirm={() => {
                                    setSelectedMch(record);
                                    enableWxMch(record);
                                }}>
                        <Button type={'link'} disabled={record.status === 'enable'} size={"small"}>启用</Button>
                    </Popconfirm>
                </Space>
            }
        }
    ];

    const [wxList, setWxList] = useState([])

    useEffect(() => {
        getWxMchList();
    }, []);

    /**
     * 获取微信商户号列表
     */
    const getWxMchList = () => {
        wxConfigService.getWxList().then(res => {
            console.log(res.data);
            if (res.data.status === 0) {
                setWxList(res.data.data)
            } else {
                msgApi.error(res.data.msg);
            }
        }).catch(err => {
            msgApi.error(err);
        })
    }

    const enableWxMch = (mch: any) => {
        wxConfigService.enableMch(mch)
            .then(res => {
                const responseMsg = res.data;
                if (responseMsg.status === 0) {
                    getWxMchList();
                } else {
                    msgApi.error(responseMsg.msg).then();
                }
            })
            .catch(err => {
                msgApi.error("发生未知错误")
            })
    }

    return <div>
        {messageCtxHolder}
        <Row style={{paddingBottom: '15px'}}>
            <Col>
                <Button type={'primary'}
                        onClick={() => {
                            setSelectedMch(null);
                            setShowMchForm(true);
                        }}
                >新增商户号</Button>
            </Col>
        </Row>
        <Table rowKey={"id"}
               columns={columns}
               dataSource={wxList}
        />
        <Modal open={showMchForm}
               closable={true}
               footer={null}
               destroyOnClose={true}
               maskClosable={false}
               onCancel={() => setShowMchForm(false)}>
            <WxMchForm mch={selectedMch} onSuccess={() => {
                getWxMchList();
                setShowMchForm(false);
            }}/>
        </Modal>
    </div>
}
