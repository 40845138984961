export class UserService {

    getUserByLocalStorage() {
        const user = localStorage.getItem("_user_");
        if (user != null) {
            return JSON.parse(user);
        } else {
            return null;
        }
    }

    setUserByLocalStorage(user: any) {
        localStorage.setItem("_user_", JSON.stringify(user));
    }

}

export const userService = new UserService();
