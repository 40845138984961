import {Button, Form, Input, InputNumber, message, Select} from "antd";
import {useEffect, useState} from "react";
import {packageService} from "../../service/package";


export interface IPackageItemFormProps {
    packageItem?: any;
    defaultPackageGroupId?: number;
    packageGroupList?: { id: number, title: string, packageGroupType: string, description: string }[];
    onSuccess?: (packageGroup: any) => void;

}

export const PackageItemForm = (props: IPackageItemFormProps) => {
    const {
        onSuccess,
        packageItem,
        defaultPackageGroupId,
        packageGroupList
    } = props;
    // 全局提示
    const [msgApi, contextHolder] = message.useMessage();

    const [form] = Form.useForm();

    const [mode, setMode] = useState<"edit" | "create">(() => packageItem ? "edit" : "create")

    const [submitting, setSubmitting] = useState(false);

    useEffect(() => {
        if (packageItem) {
            fillForm(packageItem);
        } else if (defaultPackageGroupId) {
            fillDefaultForm(defaultPackageGroupId);
        }
    }, []);

    const fillDefaultForm = (packageGroupId: number) => {
        form.setFieldsValue({
            packageGroupId: packageGroupId,
            unit: "day",
            packageStatus: "enable"
        })
    }
    const fillForm = (packageItem: any) => {
        // console.log("回填套餐表单：", packageItem);
        const {value, unit} = JSON.parse(packageItem.packageTime);
        // console.log("value, unit", value, unit);
        form.setFieldsValue({
            packageName: packageItem.packageName,
            packagePrice: packageItem.packagePrice,
            packagePayPrice: packageItem.packagePayPrice,
            packageGroupId: packageItem.packageGroupId,
            packageStatus: packageItem.packageStatus,
            packageDesc: packageItem.packageDesc,
            value: value,
            unit: unit,
        });
    }


    const onFinish = (values: any) => {
        // console.log('package item form submit:', values);
        setSubmitting(true);
        const param = {...values};
        // 套餐时长要转换一下
        param.packageTime = JSON.stringify({
            value: values.value,
            unit: values.unit
        });
        if (mode === "edit") {
            // 如果是编辑套餐，除了可编辑的项，其他项也得回填
            const _finalParam = Object.assign({}, packageItem, param);
            packageService.updatePackageItem(_finalParam)
                .then(res => {
                    const responseMsg = res.data;
                    if (responseMsg.status == 0) {
                        // console.log("新增套餐成功", responseMsg.data);
                        onSuccess && onSuccess(responseMsg.data);
                        // form.resetFields();
                        // setSubmitting(false);
                    } else {
                        // console.log("新增套餐组失败");
                        msgApi.error(responseMsg.msg).then(() => setSubmitting(false));
                    }
                })
                .catch(err => {
                    // console.log("err", err);
                    msgApi.error("网页发生错误，请联系管理员").then(() => setSubmitting(false));
                })
        } else {
            packageService.createPackageItem(param)
                .then(res => {
                    const responseMsg = res.data;
                    if (responseMsg.status == 0) {
                        // console.log("新增套餐成功", responseMsg.data);
                        onSuccess && onSuccess(responseMsg.data);
                        // form.resetFields();
                        // setSubmitting(false);
                    } else {
                        // console.log("新增套餐组失败");
                        msgApi.error(responseMsg.msg).then(() => setSubmitting(false));
                    }
                })
                .catch(err => {
                    // console.log("err", err);
                    msgApi.error("网页发生错误，请联系管理员").then(() => setSubmitting(false));
                })
        }

    }


    return (
        <div style={{
            border: "0px solid red",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            padding: "20px 30px 0 0"
        }}>
            {contextHolder}
            <Form form={form}
                  labelCol={{span: 8}}
                  wrapperCol={{span: 16}}
                  autoComplete="off"
                  style={{width: "1000px", border: "0px solid blue"}}
                  onFinish={onFinish}
            >
                <Form.Item
                    name={"packageName"}
                    label={"套餐名称"}
                    rules={[{required: true, message: '套餐名称不能为空!'}]}
                >
                    <Input/>
                </Form.Item>

                <Form.Item
                    name={"packagePrice"}
                    label={"套餐原价（显示价格）"}
                    rules={[{required: true, message: '套餐价格不能为空!'}]}
                >
                    <InputNumber placeholder={"填写用于显示的套餐原价"} style={{width: "100%"}}/>
                </Form.Item>

                <Form.Item
                    name={"packagePayPrice"}
                    label={"套餐实际价格"}
                    rules={[{required: true, message: '套餐名称不能为空!'}]}
                >
                    <InputNumber placeholder={"填写套餐的实际购买价格"} style={{width: "100%"}}/>
                </Form.Item>

                <Form.Item
                    name={"packageGroupId"}
                    label={"所属套餐组"}
                    rules={[{required: true, message: '套餐组不能为空!'}]}
                >
                    <Select
                        options={packageGroupList?.map((item: any) => {
                            return {label: item.title, value: item.id}
                        })}
                        placeholder={"请选择套餐组"}/>
                </Form.Item>

                <Form.Item
                    name={"packageStatus"}
                    label={"套餐状态"}
                    rules={[{required: true, message: '请选择套餐状态!'}]}
                >
                    <Select>
                        <Select.Option value={"enable"}>启用</Select.Option>
                        <Select.Option value={"disable"}>禁用</Select.Option>
                    </Select>
                </Form.Item>

                <Form.Item
                    name={"packageDesc"}
                    label={"套餐描述"}
                    rules={[{required: true, message: '套餐描述不能为空!'}]}
                >
                    <Input.TextArea/>
                </Form.Item>

                <Form.Item
                    name={"value"}
                    label={"套餐生效时间"}
                    rules={[{required: true, message: '套餐生效时间不能为空!'}]}
                >
                    <InputNumber addonAfter={
                        <Form.Item name={"unit"} noStyle>
                            <Select style={{width: 80}}>
                                <Select.Option value="day">天</Select.Option>
                                <Select.Option value="month">月</Select.Option>
                                <Select.Option value="hour">小时</Select.Option>
                            </Select>
                        </Form.Item>

                    }/>

                </Form.Item>

                <Form.Item wrapperCol={{offset: 8, span: 16}}>
                    <Button type="primary" htmlType="submit" loading={submitting}>
                        {
                            mode === "edit" ? "保存修改" : "新增套餐"
                        }
                    </Button>
                </Form.Item>
            </Form>
        </div>
    )
}
