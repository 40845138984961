import {$7DaysProductColumn,$7DaysProductTable} from "../../components";

export const DataView = () => {



    return <div>
        <$7DaysProductColumn/>
        <$7DaysProductTable/>
    </div>
}