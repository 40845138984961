import {httpService} from "../http-service";

export class OrderService {


    queryOrderList(param: {
        orderNo?: string
        deviceId?: string
        userId?: string
        status?: string
        beginTime?: number
        endTime?: number
        thirdPartyOrderNo?: string
        batchId?: number
        pageNum: number
        pageSize: number
    }) {
        return httpService.post("/order/list", param)
    }

    $7DaysProductData() {
        const url = "/order/7DaysProductData";
        const cacheKey = `cache_${url}`;
        const cached = localStorage.getItem(cacheKey);
        const now = new Date().getTime();
        if (cached) {
            const {data, timestamp} = JSON.parse(cached);
            // 检查缓存是否过期
            if (now - timestamp < 300000) {
                return Promise.resolve(data); // 如果缓存有效，返回缓存数据
            }
        }
        return httpService.post(url, {}).then(res => {
            // 缓存新的数据和时间戳
            localStorage.setItem(cacheKey, JSON.stringify({data: res, timestamp: now}));
            return res; // 返回新的数据
        })

    }

    exportOrderList(param: {
        thirdPartyOrderNo: string | undefined;
        orderNo: string | undefined;
        pageSize: number;
        batchId: number | undefined;
        pageNum: number;
        deviceId: string | undefined;
        status: any
    }) {
        return httpService.post("/order/list/export", param)
    }
}

export const orderService = new OrderService();
