import {Button, Col, Divider, Drawer, message, Modal, Popconfirm, QRCode, Row, Select, Space} from "antd";
import React, {useEffect, useMemo, useState} from "react";
import {deviceService} from "../../service/device";

export interface IDeviceOperationProps {
    visible: boolean;
    device: any;
    onClose: () => void;
    deviceBatchList: any[];
}

export const DeviceOperation = (props: IDeviceOperationProps) => {

    const [msgApi, contextHolder] = message.useMessage()

    const {visible, device = {}, onClose, deviceBatchList} = props;
    const [modalOpen, setModalOpen] = useState(false);
    // 新的选中的下拉列表批次
    const [selectedBatchId, setSelectedBatchId] = useState<number | undefined>();

    const qrcodeText = useMemo(() => {
        return `${process.env.REACT_APP_QRCODE_BASEURL}?deviceId=${device.deviceId}&deviceType=${device.deviceTypeName}`
    }, [device]);

    // useEffect(() => {
    //     console.log("device", device);
    //     console.log("deviceBatch", deviceBatchList);
    // }, [device]);


    /**
     * 手动停机
     */
    const shutdownDevice = () => {
        if (device.deviceStatus == "INACTIVE") {
            msgApi.error("设备已停机").then();
            return;
        }
        deviceService.deviceShutdown(device.deviceId)
            .then(res => {
                // console.log("deviceShutdown res", res);
                const responseMsg = res.data;
                if (responseMsg.status == 0) {
                    msgApi.success("停机成功").then();
                    onClose();
                } else {
                    msgApi.error(responseMsg.msg).then();
                }
            })
            .catch(err => {
                console.log("err", err);
                msgApi.error("发生未知错误，请联系管理员").then();
            })
    }

    const updateDeviceBatch = () => {
        if (!selectedBatchId) {
            msgApi.error("请选择批次").then();
            return;
        }
        deviceService.updateDeviceBatchId({
            deviceId: device.deviceId,
            batchId: selectedBatchId
        }).then(res => {
            // console.log("updateDeviceBatchId res", res);
            const responseMsg = res.data;
            if (responseMsg.status === 0) {
                msgApi.success("更改成功").then();
                setModalOpen(false);
                onClose();
            } else {
                msgApi.error(responseMsg.msg).then();
            }
        })
            .catch(err => {
                console.log("err", err);
                msgApi.error("发生未知错误，请联系管理员").then();
            })
    }

    return <Drawer title="设备详情和操作" placement="right" onClose={() => onClose()} open={visible}>
        {contextHolder}
        <Space direction={"vertical"} style={{width: "100%"}}>
            <Divider>
                详情
            </Divider>
            <Row>
                <Col span={8}>设备号</Col>
                <Col span={6}>{device.deviceId}</Col>
            </Row>
            <Row>
                <Col span={8}>导入批次</Col>
                <Col span={6}>{device.batchName}</Col>
            </Row>
            <Row>
                <Col span={8}>设备类型</Col>
                <Col span={6}>{device.deviceTypeTitle}</Col>
            </Row>
            <Row>
                <Col span={8}>
                    设备二维码
                </Col>
                <Col span={16}>
                    <QRCode value={qrcodeText} type={'svg'} size={130}/>
                </Col>
            </Row>
            <Divider>
                操作
            </Divider>
            <Row>
                <Col span={12}>
                    <Popconfirm title={"确定要停机吗？"}
                                onConfirm={shutdownDevice}
                                okText={"确定"}
                                cancelText={"取消"}
                    >
                        <Button type={'primary'} danger={true}>手动停机</Button>
                    </Popconfirm>
                </Col>

                <Col span={12}>
                    <Button type={'primary'} onClick={() => setModalOpen(true)}>更改所属批次</Button>
                </Col>
            </Row>

        </Space>


        <Modal title="更改设备所属批次号"
               open={modalOpen}
               onCancel={() => setModalOpen(false)}
               destroyOnClose={true}
               okText={"确定"}
               cancelText={"取消"}
               onOk={updateDeviceBatch}
        >

            <Row style={{border: "0px solid red"}}>
                <Col span={4} style={{border: "0px solid blue", textAlign: "right"}}>
                    <p>选择批次：</p>
                </Col>
                <Col span={20} style={{
                    border: "0px solid blue",
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center'
                }}>

                    <Select
                        style={{width: '100%'}}
                        options={deviceBatchList
                            .filter(item => item.id !== device.batchId)
                            .map(item => {
                                return {label: item.batchName, value: item.id}
                            })}
                        onChange={(value: number) => {
                            setSelectedBatchId(value)
                        }}
                        showSearch={true}
                        optionFilterProp="label"
                        filterOption={(input, option) => {
                            return option?.label?.toLowerCase().indexOf(input.toLowerCase()) >= 0
                        }}
                        placeholder='请选择导入批次'
                        maxTagCount={1}
                    />
                </Col>

            </Row>

        </Modal>
    </Drawer>
};
