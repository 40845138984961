import {Button, Form, Input, InputNumber, message, Select, Upload} from "antd";
import {UploadOutlined} from "@ant-design/icons";
import {useEffect, useState} from "react";
import {deviceService} from "../../service/device";
import {packageService} from "../../service/package";
import {RcFile} from "antd/es/upload/interface";

export interface IImportDeviceFormProps {
    onImportSuccess?: (deviceBatch: any) => void
    onClose?: () => void
    deviceBatch?: any
}

export const ImportDeviceForm = (props: IImportDeviceFormProps) => {

    const {onImportSuccess, deviceBatch} = props;
    const [deviceTypeList, setDeviceTypeList] = useState<{ value: number, label: string }[]>([]);
    const [giftPackageList, setGiftPackageList] = useState<any[]>([]);
    const [buyPackageGroupList, setBuyPackageGroupList] = useState<any[]>([])
    const [submitting, setSubmitting] = useState(false);
    const [form] = Form.useForm();

    let _file: any;

    useEffect(() => {
        init();
    }, [])

    const init = () => {
        // 获取所有设备类型
        deviceService.getEnableDeviceTypeList().then(res => {
            // console.log("res", res)
            const responseMsg = res.data;
            if (responseMsg.status === 0) {
                const selectionList: { value: number, label: string }[] = responseMsg.data.map((v: any) => {
                    // console.log("v:", v);
                    return {value: v.id, label: v.title}
                });
                // console.log(selectionList);
                setDeviceTypeList(selectionList)
            } else {
                message.error(responseMsg.msg).then();
            }
        })
        // 获取所有可用的套餐组
        packageService.enablePackageGroupList()
            .then(res => {
                // console.log("res", res);
                const responseMsg = res.data;
                if (responseMsg.status === 0) {
                    const giftList: any[] = [];
                    const buyList: any[] = [];
                    responseMsg.data.forEach((v: any) => {
                        if (v.packageGroupType == "buy") {
                            buyList.push({value: v.id, label: v.title});
                        } else {
                            giftList.push({value: v.id, label: v.title});
                        }
                    })
                    setGiftPackageList(giftList);
                    setBuyPackageGroupList(buyList);
                    fillDeviceBatch();
                }
            });
    }

    // 创建批次，导入设备
    const onFinish = (values: any) => {
        setSubmitting(true);
        console.log('Success:', values);
        Promise.resolve(values)
            .then(res => {
                // 如果有ID
                if (res.batchId) return res.batchId;
                return deviceService.createDeviceBatch(values)
                    .then(res => {
                        // console.log("res", res);
                        const responseMsg = res.data;
                        if (responseMsg.status === 0) {
                            // 创建批次号成功
                            const _batchId = responseMsg.data.id;
                            console.log("batchId", _batchId);
                            return _batchId;
                        } else {
                            throw new Error(responseMsg.msg);
                        }
                    })
            })
            .then(_batchId => {
                deviceService.importDevice(_batchId, _file)
                    .then(res1 => {
                        // console.log(res1);
                        const responseMsg1 = res1.data;
                        if (responseMsg1.status !== 0) {
                            // message.error(responseMsg1.msg).then();
                            setSubmitting(false);
                            throw new Error(responseMsg1.msg);
                        }
                        // message.success("导入成功").then();
                        onImportSuccess && onImportSuccess(responseMsg1.data);
                    })
            })
            .catch(err => {
                message.error(err || "网络错误,请联系管理员").then();
                setSubmitting(false);
            })
    };

    const onFinishFailed = (errorInfo: any) => {
        console.log('Failed:', errorInfo);
    };

    const fillDeviceBatch = () => {
        if (!deviceBatch) return;
        form.setFieldsValue({
            batchId: deviceBatch.id,
            batchName: deviceBatch.batchName,
            deviceTypeId: deviceBatch.deviceTypeId,
            payPackageGroupId: deviceBatch.payPackageGroupId,
            giftPackageGroupId: deviceBatch.giftPackageGroupId,
        })
    }

    const mockImport = () => {
        const batchId = 6;
        // console.log(_file);
        deviceService.importDevice(batchId, _file)
            .then(res => {
                console.log(res);
            })
            .catch(err => {
            });
    }


    const beforeUpload = (file: RcFile) => {
        // console.log(file);
        _file = file;
        return false;
    };

    return <div style={{
        border: "0px solid red",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        padding: "20px 30px 0 0"
    }}>

        <Form
            name="basic"
            labelCol={{span: 8}}
            wrapperCol={{span: 16}}
            style={{width: "800px", border: "0px solid blue"}}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            autoComplete="off"
            form={form}
        >
            <Form.Item label={"批次号ID"} name={'batchId'} style={{display: "none"}}>
                <InputNumber/>
            </Form.Item>

            <Form.Item
                label="请输入批次名称"
                name="batchName"
                rules={[{required: true, message: '批次名称不能为空!'}]}
            >
                <Input disabled={deviceBatch !== undefined}/>
            </Form.Item>

            <Form.Item
                label={"请选择设备类型"}
                name={"deviceTypeId"}
                rules={[{required: true, message: '设备类型不能为空!'}]}
            >
                <Select
                    options={deviceTypeList}
                    disabled={deviceBatch !== undefined}
                />
            </Form.Item>

            <Form.Item
                label={"请选择付费套餐组"}
                name={"payPackageGroupId"}
                rules={[{required: true, message: '付费套餐不能为空!'}]}
            >
                <Select
                    options={buyPackageGroupList}
                    disabled={deviceBatch !== undefined}
                />
            </Form.Item>

            <Form.Item
                label={"请选择免费套餐"}
                name={"giftPackageGroupId"}
            >
                <Select
                    options={giftPackageList}
                    allowClear={true}
                    disabled={deviceBatch !== undefined}
                />
            </Form.Item>

            <Form.Item
                label={"设备列表"}
                name={"file"}
                rules={[{required: true, message: '请上传文件!'}]}
            >
                <Upload name={'file'} accept={"xlsx"} beforeUpload={beforeUpload}>
                    <Button icon={<UploadOutlined/>}>请选择文件</Button>
                </Upload>
            </Form.Item>

            <Form.Item wrapperCol={{offset: 8, span: 16}}>
                <Button type="primary" htmlType="submit" loading={submitting}>
                    导入设备
                </Button>
            </Form.Item>

            {/*<Form.Item wrapperCol={{offset: 8, span: 16}}>*/}
            {/*    <Button type="primary" onClick={mockImport} loading={submitting}>*/}
            {/*        测试导入*/}
            {/*    </Button>*/}
            {/*</Form.Item>*/}
        </Form>

    </div>
}
