import {Button, Form, Input, message, Select} from "antd";
import {useEffect, useState} from "react";
import {wxConfigService} from "../../service";

export interface WxMchFormProps {
    mch?: any;
    onSuccess?: (mch: any) => void;
}

export const WxMchForm = (props: WxMchFormProps) => {

    const {mch,onSuccess} = props;
    const [msgApi, messageCtxHolder] = message.useMessage()
    const [form] = Form.useForm();

    const [submitting, setSubmitting] = useState(false);

    const onFinish = (values: any) => {
        setSubmitting(true);
        // console.log(values);
        wxConfigService.addOrUpdate(values)
            .then(res => {
                const responseMsg = res.data;
                if (responseMsg.status === 0) {
                    if (onSuccess) {
                        onSuccess(responseMsg.data);
                    }
                } else {
                    msgApi.error(responseMsg.msg)
                }
                setSubmitting(false);
            })
            .catch(err => {
                msgApi.error(err);
                setSubmitting(false);
            })
    }

    useEffect(() => {
        if (mch)
            fillForm(mch);
    }, []);

    const fillForm = (mch: any) => {
        form.setFieldsValue({
            ...mch
        });
    }


    return <div style={{
        border: "0px solid red",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        padding: "20px 30px 0 0"
    }}>
        {messageCtxHolder}
        <Form form={form}
              labelCol={{span: 8}}
              wrapperCol={{span: 16}}
              autoComplete="off"
              style={{width: "1000px", border: "0px solid blue"}}
              onFinish={onFinish}
        >
            <Form.Item style={{display: "none"}} labelCol={{span: 8}} wrapperCol={{span: 16}} label={"序号"}
                       name={"id"}>
                <Input/>
            </Form.Item>
            <Form.Item
                name={"mchId"}
                label={"商户号"}
                rules={[{required: true, message: '商户号不能为空!'}]}
            >
                <Input disabled={!!mch}/>
            </Form.Item>

            <Form.Item
                name={"mchName"}
                label={"商户名称"}
                rules={[{required: true, message: '商户号不能为空!'}]}
            >
                <Input/>
            </Form.Item>

            <Form.Item
                name={"mchKey"}
                label={"商户V2秘钥"}
                rules={[{required: true, message: '秘钥不能为空!'}]}
            >
                <Input.Password/>
            </Form.Item>


            <Form.Item
                name={"description"}
                label={"商户号描述信息"}
                rules={[{required: true, message: '描述信息不能为空'}]}
            >
                <Input.TextArea/>
            </Form.Item>

            <Form.Item wrapperCol={{offset: 8, span: 16}}>
                <Button type="primary" htmlType="submit" loading={submitting}>
                    {
                        mch ? "保存修改" : "新增商户号"
                    }
                </Button>
            </Form.Item>
        </Form>

    </div>
}
