import {Button, Col, message, Modal, notification, Popconfirm, Row, Table} from "antd";
import React, {useCallback, useEffect, useState} from "react";
import dayjs from "dayjs";
import {PackageGroupForm} from "./PackageGroupForm";
import {packageService} from "../../service/package";

export const PackageGroupList = () => {

    const [msgApi, contextHolder] = message.useMessage()
    const [noticeApi, noticeContextHolder] = notification.useNotification();

    // 套餐组列表
    const [packageGroupList, setPackageGroupList] = useState<any[]>([]);
    // 新增套餐组弹窗
    const [showAddModal, setShowAddModal] = useState(false);

    const columns = [
        {
            key: "id",
            dataIndex: "id",
            title: "套餐组ID",
        },
        {
            key: "title",
            dataIndex: "title",
            title: "套餐组名称",
        },
        {
            key: "description",
            dataIndex: "description",
            title: "套餐组描述",
        },
        {
            key: "status",
            dataIndex: "status",
            title: "套餐组状态",
            render: (text: any) => {
                if (text === "enable") {
                    return <span style={{color: "green"}}>启用</span>
                } else if (text === "disable") {
                    return <span style={{color: "gray"}}>禁用</span>
                } else {
                    return <span style={{color: "red"}}>未知</span>
                }
            }
        },
        {
            key: "packageGroupType",
            dataIndex: "packageGroupType",
            title: "套餐类型",
            render: (text: any) => {
                if (text === "gift") {
                    return "免费套餐"
                } else if (text === "buy") {
                    return "付费套餐"
                } else if (text === "manual") {
                    return "手动赠送的套餐"
                } else {
                    return "未知类型套餐";
                }
            }
        },
        {
            key: "createTime",
            dataIndex: "createTime",
            title: "创建时间",
            render: (text: any) => {
                if (text) {
                    return dayjs.unix(text).format('YYYY-MM-DD HH:mm:ss')
                }
                return text
            }
        },
        {
            key: "operation",
            dataIndex: "operation",
            title: "操作",
            render: (text: any, record: any) => {
                return <Button.Group>
                    {/*<Button type={"link"}>编辑</Button>*/}
                    <Popconfirm title={"确认删除套餐组吗？"}
                                description={"套餐组下所有套餐将会被删除"}
                                okText={"确认"}
                                cancelText={"取消"}
                                onConfirm={() => deleteGroup(record)}
                    >
                        <Button type={"link"}>删除</Button>
                    </Popconfirm>
                </Button.Group>
            }
        }
    ];

    const onPackageGroupCreateSuccess = useCallback(() => {
        setShowAddModal(false);
        msgApi.success("新增套餐组成功").then();
        search();
    }, [])

    useEffect(() => {
        search();
    }, []);

    const openNotification = (msg: any) => {
        noticeApi.error({
            message: `删除失败`,
            description: msg,
        });
    };

    const deleteGroup = (packageGroup: any) => {
        packageService.deleteGroup(packageGroup)
            .then(res => {
                const responseMsg = res.data;
                if (responseMsg.status === 0) {
                    msgApi.success("删除套餐组成功").then();
                    search();
                } else {
                    const node = <p>
                        <p>套餐组已经关联批次</p>{responseMsg.msg.split("\n").map((item: any, index: number) => {
                        return <p key={"re_item" + index}>{item}</p>
                    })}</p>;
                    openNotification(node);
                }
            })
            .catch(err => {
                msgApi.error("网页发生错误,请联系管理员").then();
            })
    }

    const search = () => {
        packageService.allPackageGroupList()
            .then(res => {
                // console.log("allPackageGroupList res", res);
                const responseMsg = res.data;
                if (responseMsg.status === 0) {
                    setPackageGroupList(responseMsg.data);
                } else {
                    msgApi.error(responseMsg.msg).then();
                }
            })
            .catch(err => {
                // console.log(err);
                msgApi.error("网页发生错误,请联系管理员").then();
            })
    }

    return <div>
        {contextHolder}
        {noticeContextHolder}
        <Row style={{paddingBottom: "15px"}}>
            <Col span={24}>
                <Button type={'primary'} onClick={() => {
                    setShowAddModal(true);
                }}>新增套餐组</Button>
            </Col>
        </Row>

        <Table
            key={"packageGroupTable"}
            rowKey={"id"}
            columns={columns}
            dataSource={packageGroupList}
        />

        <Modal open={showAddModal}
               closable={true}
               footer={null}
               destroyOnClose={true}
               maskClosable={false}
               onCancel={() => setShowAddModal(false)}>
            <PackageGroupForm onSuccess={onPackageGroupCreateSuccess}/>
        </Modal>
    </div>
}
