import {Button, Col, Form, Input, message, Modal, Row, Select, Table} from "antd";
import React, {useCallback, useEffect, useState} from "react";
import {configMonitorService} from "../../service";
import {CMImportCardConfigForm} from "./CMImportCardConfigForm";
import dayjs from "dayjs";
import {CMCCardForm} from "./CMCCardForm";

export const CMCardConfig = () => {
    const [msgApi, messageCtxHolder] = message.useMessage();
    const [cardList, setCardList] = useState([])
    const [form] = Form.useForm();
    const [iccid, setIccid] = useState<string | null>(null);
    const [operator, setOperator] = useState<string | null>(null);
    // 分页参数
    const [pageNum, setPageNum] = useState(1);
    // const pageParam = useRef({pageSize: 10, pageNum: 1});
    // 分页参数
    const [pageSize, setPageSize] = useState(10);
    // 总数
    const [total, setTotal] = useState(0);

    // 选中的卡号
    const [selectedCard, setSelectedCard] = useState<any>(null);

    const [importModalOpen, setImportModalOpen] = useState(false);
    const [cardFormModalOpen, setCardFormModalOpen] = useState(false);

    const columns = [
        {
            key: "iccid",
            dataIndex: "iccid",
            title: "ICCID"
        },
        {
            key: "operator",
            dataIndex: "operator",
            title: "通道名称",
        },
        {
            key: "config",
            dataIndex: "config",
            title: "卡配置",
            render: (text: any) => {
                return <div style={{width: "80px", overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap"}}>
                    {text}
                </div>
            }
        },
        {
            key: "createTime",
            dataIndex: "createTime",
            title: "导入时间",
            render: (text: any) => {
                if (text) {
                    return dayjs.unix(text / 1000).format('YYYY-MM-DD HH:mm:ss')
                }
                return text
            }
        }, {
            key: "operation",
            dataIndex: "operation",
            title: "操作",
            render: (text: any, record: any) => {
                return <Button type={"link"}
                               onClick={() => {
                                   setSelectedCard(record);
                                   setCardFormModalOpen(true);
                               }}
                >编辑</Button>
            }
        }
    ];

    const onFinish = (values: any) => {
        search();
    }

    const onIccidChange = (value: string) => {
        setPageNum(1);
        setIccid(value);
    }

    const onOperatorChange = (value: string) => {
        setPageNum(1);
        setOperator(value);
    }

    const onImportModalClose = () => {
        setImportModalOpen(false);
    }

    const search = useCallback(() => {
        const param = {
            pageNum: pageNum - 1,
            pageSize: pageSize,
            iccid,
            operator
        }
        configMonitorService.cardList(param)
            .then(res => {
                console.log(res.data);
                setCardList(res.data.content);
                setTotal(res.data.totalElements)
            })
            .catch(err => {
                console.log(err);
            })

    }, [iccid, operator, pageNum, pageSize])

    useEffect(() => {
        search();
    }, [search]);

    return <div>
        {messageCtxHolder}
        <Form form={form}
              labelCol={{span: 8}}
              wrapperCol={{span: 16}}
              autoComplete="off"
              style={{width: "1000px", border: "0px solid blue"}}
              onFinish={onFinish}
        >
            <Row>
                <Col span={10}>
                    <Form.Item
                        label={"ICCID"}
                        name={'iccid'}
                        labelCol={{span: 8}}
                        wrapperCol={{span: 16}}
                    >
                        <Input placeholder={'请输入ICCID进行精确查询'} allowClear={true}
                               onChange={(e) => onIccidChange(e.target.value)}/>
                    </Form.Item>
                </Col>

                {/*<Col span={10}>*/}
                {/*    <Form.Item*/}
                {/*        label={"通道标识"}*/}
                {/*        name={'operator'}*/}
                {/*        labelCol={{span: 8}}*/}
                {/*        wrapperCol={{span: 16}}*/}
                {/*    >*/}
                {/*        <Select allowClear={true} onChange={(value)=>{*/}
                {/*            onOperatorChange(value)}}>*/}
                {/*            <Select.Option value={"YD"}>YD</Select.Option>*/}
                {/*        </Select>*/}
                {/*    </Form.Item>*/}
                {/*</Col>*/}
            </Row>
        </Form>
        <Row style={{paddingBottom: '15px'}}>
            <Col>
                <Button type={'primary'}
                        onClick={() => {
                            setImportModalOpen(true);
                        }}
                >导入新规则</Button>
            </Col>
        </Row>
        <Table rowKey={"id"}
               columns={columns}
               dataSource={cardList}
               pagination={{
                   position: ["bottomRight"],
                   pageSize: pageSize,
                   current: pageNum,
                   total: total,
                   onChange: (page, pageSize) => {
                       // console.log("page", page);
                       setPageNum(page);
                       setPageSize(pageSize);
                   }
               }}
        />
        <Modal open={importModalOpen}
               closable={true}
               footer={null}
               destroyOnClose={true}
               maskClosable={false}
               onCancel={onImportModalClose}>
            <CMImportCardConfigForm onSuccess={(res: any) => {
                search();
                msgApi.success(`成功导入: ${res.result}张卡`).then();
                setImportModalOpen(false);
            }}/>
        </Modal>

        <Modal
            open={cardFormModalOpen}
            closable={true}
            footer={null}
            destroyOnClose={true}
            maskClosable={false}
            onCancel={() => {
                setCardFormModalOpen(false);
            }}
        >
            <CMCCardForm card={selectedCard} success={()=>{
                search();
                setCardFormModalOpen(false);
            }}/>
        </Modal>
    </div>

}

