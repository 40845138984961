import {Button, Form, Input, message, Select, Upload} from "antd";
import React, {useEffect, useState} from "react";
import {configMonitorService} from "../../service";

export interface CMCCardFormProps {
    card: any;
    success: (res: any) => void
}

export const CMCCardForm = (props: CMCCardFormProps) => {

    const {card} = props;
    const [msgApi, messageCtxHolder] = message.useMessage();
    const [submitting, setSubmitting] = useState(false);
    const [form] = Form.useForm();

    useEffect(() => {
        form.setFieldsValue(card);
    }, [card]);

    const onFinish = (values: any) => {
        console.log("更新卡配置", values);
        if (values.config) {
            try {
                JSON.parse(values.config);
            } catch (e) {
                console.error(e);
                msgApi.error("卡配置不是合法的JSON字符串").then();
                return;
            }
        }
        configMonitorService.updateCard(values)
            .then(res => {
                console.log(res.data);
                props.success(res.data);
            }).catch(() => {
            msgApi.error("更新卡配置失败").then();
        })

    }

    const onFinishFailed = (err: any) => {
        console.error(err);
    }

    return <div style={{
        border: "0px solid red",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        padding: "20px 30px 0 0"
    }}>
        {messageCtxHolder}
        <Form
            name="basic"
            labelCol={{span: 6}}
            wrapperCol={{span: 18}}
            style={{width: "800px", border: "0px solid blue"}}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            autoComplete="off"
            form={form}
        >

            <Form.Item
                label={"卡号"}
                name={"iccid"}
                rules={[{required: true, message: '卡号不能为空!'}]}>
                <Input disabled={true}/>
            </Form.Item>

            <Form.Item
                label={"卡配置"}
                name={"config"}
            >
                <Input.TextArea autoSize={{minRows: 15, maxRows: 25}}/>
            </Form.Item>

            <Form.Item wrapperCol={{offset: 6, span: 18}}>
                <Button type="primary" htmlType="submit" loading={submitting}>
                    确定
                </Button>
            </Form.Item>
        </Form>
    </div>
}