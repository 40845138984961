import React, {useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import {httpService, tokenService} from "../service";
import {userService} from "../service/user/user-service";
import {Layout, Spin} from "antd";

let AuthContext = React.createContext<any>(null);

export function useAuth() {
    return React.useContext(AuthContext);
}

export const AuthProvider = ({children}: any) => {
    const navigate = useNavigate();
    // const payload = tokenService.getPayload();
    const _user = userService.getUserByLocalStorage();


    useEffect(() => {
        httpService.register(10, () => {
            tokenService.clearToken();
            setUser(null)
            navigate("/auth/login", {replace: true});
        })
        // eslint-disable-next-line
    }, [])

    // 创建验证组件状态
    let [user, setUser] = useState(_user);

    // 登录验证
    let signIn = (userInfo: any, callback: () => void) => {
        // return fakeAuthProvider.signin(() => {
        //     setUser(newUser)
        //     callback()
        // })
        tokenService.setToken(userInfo.token);
        // const newUser = tokenService.getPayload();
        setUser(userInfo)
        callback();
    }

    // 退出登录
    let signOut = (callback: () => void) => {
        // return fakeAuthProvider.signout(() => {
        //     setUser(null)
        //     callback()
        // })
        setUser(null);
        sessionStorage.clear()
        tokenService.clearToken();
        callback();
    }

    let value = {user, signIn, signOut}

    // 传递验证上下文(AuthContext)属性给嵌套的插槽children子组件(App)
    return <AuthContext.Provider value={value}>
        {children}
    </AuthContext.Provider>
}
