import {Button, Col, Form, Input, message, Modal, Row, Select, Space, Table} from "antd";
import React, {useCallback, useEffect, useState} from "react";
import {configMonitorService} from "../../service";
import {CMWxMchForm} from "./CMWxMchForm";
import {ImportOutlined} from "@ant-design/icons";

export const CMPackageConfig = () => {
    const [msgApi, messageCtxHolder] = message.useMessage();
    const [form] = Form.useForm();
    const [packageConfigList, setPackageConfigList] = useState([])
    const [mpList, setMpList] = useState([]);
    const [mpListOptions, setMpListOptions] = useState<{ label: string, value: string }[]>([])

    const [showEditModal, setShowEditModal] = useState(false);
    const [selectedConfig, setSelectedConfig] = useState<any>(null)
    const [submitting, setSubmitting] = useState(false)

    const [editMode, setEditMode] = useState<"add" | "update">("add")

    /**
     * 查询方法
     */
    const search = useCallback(() => {
        configMonitorService.packageConfigList()
            .then(res => {
                console.log("所有套餐：", res.data);
                setPackageConfigList(res.data);
            })
            .catch(err => {
                console.error(err.response.data)
                msgApi.error("发生未知错误").then();
            })
    }, [])

    /**
     * 查询条件变化就执行查询
     */
    useEffect(() => {
        search();
    }, [search]);

    /**
     * 选中的记录变化，就把选中的记录的配置内容放到表单里
     */
    useEffect(() => {
        form.setFieldsValue(selectedConfig);
        // form.setFieldValue("config", selectedConfig?.config);
        // form.setFieldValue("mpAppId", selectedConfig?.mpAppId);
        // form.setFieldValue("id", selectedConfig?.id);
    }, [form, selectedConfig]);

    useEffect(() => {
        if (mpList && mpList.length > 0) {
            setMpListOptions(mpList.map((v: any) => {
                return {label: v.mpName, value: v.appId}
            }));
        }
    }, [mpList]);

    /**
     * 进页面的时候查询一次小程序列表
     */

    useEffect(() => {
        configMonitorService.getMpList()
            .then(res => {
                console.log("小程序列表：", res.data);
                setMpList(res.data);
            })
            .catch(err => {
                console.error(err)
                msgApi.error("发生未知错误").then();
            })
    }, []);

    /**
     * 更改配置内容
     * @param values
     */
    const onFinish = (values: any) => {
        setSubmitting(true);
        const {config} = values;
        try {
            JSON.parse(config);
        } catch (e) {
            msgApi.error("配置内容不是合法的JSON字符串").then();
            setSubmitting(false);
            return;
        }
        if (selectedConfig && editMode === "update") {
            configMonitorService.updatePackageConfig({
                ...values
            })
                .then(res => {
                    // console.log(res.data);
                    msgApi.success("更新成功").then();
                    search();
                    setSubmitting(false);
                    setShowEditModal(false);
                })
                .catch(err => {
                    console.error(err.response.data)
                    msgApi.error(err?.response?.data?.message || "发生未知错误").then();
                    setSubmitting(false);
                })
        } else if (editMode === "add") {
            configMonitorService.addPackageConfig({
                ...values,
            })
                .then(res => {
                    console.log(res.data);
                    msgApi.success("添加成功").then();
                    search();
                    setSubmitting(false);
                    setShowEditModal(false);
                })
                .catch(err => {
                    msgApi.error(err?.response?.data?.message || "发生未知错误").then();
                    setSubmitting(false);
                })
        }
    }

    const columns = [
        {
            key: "id",
            dataIndex: "id",
            title: "序号"
        },
        {
            key: "config",
            dataIndex: "config",
            title: "配置内容",
            render: (text: any) => {
                const displayText = text.length > 50 ? text.substring(0, 50) + "..." : text;
                return <p>{displayText}</p>
            }
        },
        {
            key: "mpAppId",
            dataIndex: "mpAppId",
            title: "应用AppId",
        },
        {
            key: "mpAppName",
            dataIndex: "mpAppName",
            title: "商户描述",
            render: (text: any, record: any) => {
                let mpName = "";
                if (mpList.length > 0) {
                    mpList.forEach((item: any) => {
                        if (item.appId == record.mpAppId) {
                            // console.log(`相等的appId:${item.appId}，text:${text},mpName:${item.mpName}`);
                            // return <p>{item.mpName}</p>
                            mpName = item.mpName;

                        }
                    })
                }
                return <p>{mpName}</p>
            }
        },
        {
            key: "operator",
            dataIndex: "operator",
            title: "通道名称",
        },
        {
            key: 'operate',
            dataIndex: 'operate',
            title: '操作',
            render: (text: any, record: any) => {
                return <Space size={'small'}>
                    <Button type={'link'} size={"small"} onClick={() => {
                        setEditMode("update");
                        setSelectedConfig(record);
                        setShowEditModal(true);
                    }}>编辑</Button>
                </Space>
            }
        }
    ];

    return <div>
        {messageCtxHolder}

        <Row>
            <Col span={24} style={{textAlign: 'right'}}>
                <Form.Item>
                    <Space size={'small'}>
                        <Button type={'primary'} onClick={() => {
                            setEditMode("add");
                            form.resetFields();
                            setShowEditModal(true);
                        }}
                                icon={<ImportOutlined/>}>创建通道</Button>
                    </Space>
                </Form.Item>
            </Col>
        </Row>

        <Table rowKey={"id"}
               columns={columns}
               dataSource={packageConfigList}
        />

        <Modal open={showEditModal}
               closable={true}
               footer={null}
               destroyOnClose={false}
               maskClosable={false}
               onCancel={() => setShowEditModal(false)}
               width={800}
        >
            <Form style={{margin: "30px 10px 0 0"}}
                  form={form}
                  onFinish={onFinish}
                  labelCol={{span: 3}}
                  wrapperCol={{span: 21}}
            >
                <Form.Item label={"id"} name={"id"} style={{display: "none"}}>
                    <Input/>
                </Form.Item>

                <Form.Item
                    label={"通道名称"}
                    name={"operator"}
                    rules={[{required: true, message: "请输入通道名称"}]}
                >
                    {/* 如果是修改则不允许编辑 */}
                    <Input placeholder={"一经确定无法修改"} disabled={editMode === "update"}/>
                </Form.Item>

                <Form.Item
                    label={"应用AppId"}
                    name={"mpAppId"}
                    rules={[{required: true, message: "请输入应用AppId"}]}
                >
                    {/* 如果是修改则不允许编辑 */}
                    <Select options={mpListOptions} disabled={editMode === "update"}/>
                </Form.Item>
                <Form.Item
                    label={"配置内容"}
                    name={"config"}
                    rules={[{required: true, message: "配置内容不能为空"}]}
                >
                    <Input.TextArea autoSize={{minRows: 25, maxRows: 25}}/>
                </Form.Item>
                <Form.Item wrapperCol={{offset: 22, span: 2}}>
                    <Button type="primary" htmlType="submit" loading={submitting}>
                        确定
                    </Button>
                </Form.Item>
            </Form>
        </Modal>
    </div>
}
