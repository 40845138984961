import {Table} from "antd";
import './$7DaysProductTable.css';
import {useEffect, useState} from "react";
import {orderService} from "../../service";

export const $7DaysProductTable = () => {
    const [loading, setLoading] = useState(true)
    const [data, setData] = useState([])

    const columns = [
        {
            key: "product_id",
            dataIndex: "product_id",
            title: "套餐ID",
        },
        {
            key: "product_name",
            dataIndex: "product_name",
            title: "套餐名称",
        },
        {
            key: "user_count",
            dataIndex: "user_count",
            title: "付费人数",
        },
        {
            key: "order_count",
            dataIndex: "order_count",
            title: "订单总数",
        },
        {
            key: "total_pay_price",
            dataIndex: "total_pay_price",
            title: "总金额",
        },
        {
            key: "order_date",
            dataIndex: "order_date",
            title: "日期",
        }
    ]
    useEffect(() => {
        orderService.$7DaysProductData().then((res) => {
            // console.log("res", res)
            setData(res.data.data)
            setLoading(false)
        })
    }, []);

    return <div>
        <div className={'title'}>
            <span>7日套餐购买表格</span>
        </div>
        <Table columns={columns}
               rowKey={(record:any) => `${record.product_id}_${record.order_date}`}
               loading={loading}
               dataSource={data}
               pagination={{
                   total: data.length,
               }}/>
    </div>
}