import React, {useCallback, useEffect, useState} from "react";
import {message, Table} from "antd";
import dayjs from "dayjs";
import {configMonitorService} from "../../service";

export const CMCardOrderLog = () => {
    const [msgApi, messageCtxHolder] = message.useMessage();
    const [pageNum, setPageNum] = useState(1); // 页码，初始值为1
    const [pageSize, setPageSize] = useState(10); // 每页行数，初始值为10
    const [total, setTotal] = useState(0); // 总数据数，初始值为0
    const [logList, setLogList] = useState([])

    const columns = [
        {
            key: "iccid",
            dataIndex: "iccid",
            title: "ICCID"
        },
        {
            key: "packageId",
            dataIndex: "packageId",
            title: "订购的套餐ID",
        },
        {
            key: "status",
            dataIndex: "status",
            title: "状态",
            render:(text:string)=>{
                if(text ==="fail"){
                    return <span style={{color:'red'}}>订购失败</span>
                }else if(text ==="fail"){
                    return <span style={{color:'green'}}>订购成功</span>
                }
                return <span style={{color:'gray'}}>未知</span>
            }
        },
        {
            key: "originPost",
            dataIndex: "originPost",
            title: "原始报文",
        },
        {
            key: "createTime",
            dataIndex: "createTime",
            title: "订购时间",
            render: (text: any) => {
                if (text) {
                    return dayjs.unix(text / 1000).format('YYYY-MM-DD HH:mm:ss')
                }
                return text
            }
        }
    ];

    const search = useCallback(() => {
        configMonitorService.listCardLog({
            pageNum: pageNum - 1,
            pageSize
        })
            .then(res => {
                // console.log(res.data);
                setLogList(res.data.content);
                setTotal(res.data.totalElements);
            })
            .catch(() => {
                msgApi.error("出错了！请稍后再试").then();
            })
    }, [pageNum, pageSize])

    useEffect(() => {
        search()
    }, [search]);

    return <div>
        {messageCtxHolder}
        <Table rowKey={"id"}
               columns={columns}
               dataSource={logList}
               pagination={{
                   position: ["bottomRight"],
                   pageSize: pageSize,
                   current: pageNum,
                   total: total,
                   onChange: (page, pageSize) => {
                       // console.log("page", page);
                       setPageNum(page);
                       setPageSize(pageSize);
                   }
               }}
        />
    </div>
}
