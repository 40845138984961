import React, {useEffect, useRef, useState} from "react";
import {configMonitorService} from "../../service";
import {Button, Col, message, Modal, Row, Space, Table} from "antd";
import {CMWxMchForm} from "./CMWxMchForm";

/**
 * 监控系统商户号列表
 * @constructor
 */
export const CMWxMchList = () => {
    const [msgApi, messageCtxHolder] = message.useMessage();

    const [showMchForm, setShowMchForm] = useState(false);

    const [selectedMch, setSelectedMch] = useState<any>(null);

    const changeMode = useRef<"info" | "key">("info");


    const columns = [
        {
            key: "mchId",
            dataIndex: "mchId",
            title: "商户号"
        },
        {
            key: "mchName",
            dataIndex: "mchName",
            title: "商户名称",
        },
        {
            key: "description",
            dataIndex: "description",
            title: "商户描述",
        },
        {
            key: 'operate',
            dataIndex: 'operate',
            title: '操作',
            render: (text: any, record: any) => {
                return <Space size={'small'}>
                    <Button type={'link'} size={"small"} onClick={() => {
                        changeMode.current = "info";
                        setSelectedMch(record);
                        setShowMchForm(true);
                    }}>编辑</Button>
                    <Button type={'link'} size={"small"} onClick={() => {
                        changeMode.current = "key";
                        setSelectedMch(record);
                        setShowMchForm(true);
                    }}>设置秘钥</Button>
                    {/*<Button type={'link'} danger={true} size={"small"} onClick={() => {*/}
                    {/*    setSelectedMch(record);*/}
                    {/*}}>删除</Button>*/}

                </Space>
            }
        }
    ];

    const [wxList, setWxList] = useState([])

    useEffect(() => {
        getWxMchList();
    }, []);

    const getWxMchList = () => {
        configMonitorService.getMchList()
            .then(res => {
                console.log(res.data);
                setWxList(res.data);
            })
            .catch(err => {
                console.error(err);
            })
    }

    return <div>
        {messageCtxHolder}
        <Row style={{paddingBottom: '15px'}}>
            <Col>
                <Button type={'primary'}
                        onClick={() => {
                            setSelectedMch(null);
                            setShowMchForm(true);
                        }}
                >新增商户号</Button>
            </Col>
        </Row>
        <Table rowKey={"id"}
               columns={columns}
               dataSource={wxList}
        />
        <Modal open={showMchForm}
               closable={true}
               footer={null}
               destroyOnClose={true}
               maskClosable={false}
               onCancel={() => setShowMchForm(false)}>
            <CMWxMchForm mch={selectedMch}
                         changeMode={changeMode.current}
                         onSuccess={() => {
                             getWxMchList();
                             setShowMchForm(false);
                         }}/>
        </Modal>
    </div>;
}
