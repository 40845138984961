import {Button, Form, Select, Upload} from "antd";
import {UploadOutlined} from "@ant-design/icons";
import {useEffect, useState} from "react";
import {RcFile} from "antd/es/upload/interface";
import {configMonitorService} from "../../service";


export const CMImportCardConfigForm = (props:{
    onSuccess: (res:any)=>void;
}) => {

    const [submitting, setSubmitting] = useState(false);
    const [form] = Form.useForm();
    const [operatorList, setOperatorList] = useState<{label:string,value:string}[]>([])
    let _file: any = null;

    useEffect(() => {
        configMonitorService.packageConfigList()
            .then(res => {
                console.log("所有套餐",res.data);
                if(res.data){
                    setOperatorList(res.data.map((v: any) => {
                        return {label:v.operator, value: v.operator}
                    }));
                }
            });
    }, []);

    const onFinish = (values: any) => {
        console.log(values);
        setSubmitting(true);
        const formData = new FormData();
        formData.append("file", _file);
        formData.append("operator", values.operator);
        console.log(formData);
        const headers = {
            // 'Content-Type': 'multipart/form-data',
        };
        configMonitorService.importCard(formData,headers)
            .then(res=>{
                // {result: 数量}
                // console.log(res.data);
                props.onSuccess(res.data);
            })
            .catch(err=>{
                // console.error(err);
            })

    }

    const onFinishFailed = (err: any) => {
        console.error(err);
    }


    const beforeUpload = (file: RcFile) => {
        _file = file;
        return false;
    };

    return <div style={{
        border: "0px solid red",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        padding: "20px 30px 0 0"
    }}>

        <Form
            name="basic"
            labelCol={{span: 8}}
            wrapperCol={{span: 16}}
            style={{width: "800px", border: "0px solid blue"}}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            autoComplete="off"
            form={form}
        >


            <Form.Item
                label="请输入套餐标识"
                name="operator"
                rules={[{required: true, message: '套餐标识不能为空!'}]}
            >
                <Select options={operatorList}/>
            </Form.Item>

            <Form.Item
                label={"卡号列表"}
                name={"file"}
                rules={[{required: true, message: '请上传文件!'}]}
            >
                <Upload name={'file'} accept={"xlsx"} beforeUpload={beforeUpload}>
                    <Button icon={<UploadOutlined/>}>请选择文件</Button>
                </Upload>
            </Form.Item>

            <Form.Item wrapperCol={{offset: 8, span: 16}}>
                <Button type="primary" htmlType="submit" loading={submitting}>
                    导入设备
                </Button>
            </Form.Item>
        </Form>

    </div>
};
