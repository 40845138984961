import {deviceService} from "../../service/device";
import {Button, Col, Form, message, Modal, notification, Popconfirm, Row, Space, Table} from "antd";
import React, {useCallback, useEffect, useState} from "react";
import dayjs from "dayjs";
import {BatchEditForm} from "./BatchEditForm";
import {ImportDeviceForm} from "../device";
import {ImportOutlined} from "@ant-design/icons";

export const DeviceBatchList = () => {

    const [msgApi, contextHolder] = message.useMessage();
    const [noticeApi, noticeContextHolder] = notification.useNotification();


    const [batchList, setBatchList] = useState<any>([])
    const [selectedBatch, setSelectedBatch] = useState<any>();
    const [deleteConfirm, setDeleteConfirm] = useState(false)
    const [showFormModal, setShowFormModal] = useState(false);
    // 导入设备弹窗打开
    const [importDeviceOpen, setImportDeviceOpen] = useState(false)

    useEffect(() => {
        init();
    }, []);


    const init = () => {
        _search();
    }

    const _search = () => {
        deviceService.getDeviceBatchList({})
            .then(res => {
                const responseMsg = res.data;
                if (responseMsg.status === 0) {
                    // console.log("responseMsg.data", responseMsg.data);
                    setBatchList(responseMsg.data)
                } else {
                    msgApi.error(responseMsg.msg).then();
                }

            })
            .catch(err => {
                console.error(err)
                msgApi.error("发生未知错误")
            })
    }

    const _deleteBatch = (batch?: any) => {
        console.log(batch);
        setSelectedBatch(batch);
        if (!deleteConfirm) {
            setDeleteConfirm(true);
        }
    }

    const _doDeleteBatch = () => {
        deviceService.deleteDeviceBatch(selectedBatch)
            .then(res => {
                const responseMsg = res.data;
                if (responseMsg.status === 0) {
                    msgApi.success("删除成功").then();
                    setDeleteConfirm(false);
                    _search();
                } else {
                    msgApi.error(responseMsg.msg).then();
                }
            })
            .catch(err => {
                console.error(err)
                msgApi.error("发生未知错误")
            })

        // console.log("要删除的批次名称：",selectedBatch?.batchName);

    }

    const formClose = () => {
        setShowFormModal(false);
    }

    const onImportSuccess = (result: any) => {
        // console.log("导入结果：", result);
        setImportDeviceOpen(false);
        openNotificationWithIcon(result);
        setSelectedBatch(undefined);
        _search();
    }

    const onImportModalClose = ()=>{
        setImportDeviceOpen(false);
        setSelectedBatch(undefined);
    }

    const openNotificationWithIcon = useCallback((result: {
        total: number,
        successCount: number,
        failCount: number,
        duplicateCount: number
    }) => {
        noticeApi.info({
            message: '导入结果通知',
            description: <p>
                共导入{result.total}条数据<br/>
                成功<span style={{color: 'green'}}>{result.successCount}</span>条<br/>
                覆盖<span style={{color: 'red'}}>{result.duplicateCount}</span>条,<br/>
                其他原因失败<span style={{color: 'red'}}>{result.failCount}</span>条
            </p>
        });
    }, [noticeApi]);

    const columns = [
        {
            title: '批次号',
            dataIndex: 'id',
            key: 'id',
        },
        {
            title: '批次名称',
            dataIndex: 'batchName',
            key: 'batchName',
        },
        {
            title: '批次卡数量',
            dataIndex: 'deviceCount',
            key: 'deviceCount',
            render:(text: any, record: any) => {
                if(text){
                    return <span>{text}</span>
                }else{
                    return <span>0</span>
                }
            }
        },
        {
            title: '批次状态',
            dataIndex: 'batchStatus',
            key: 'batchStatus',
            render: (text: any, record: any) => {
                if (text === "enable") {
                    return <span style={{color: "green"}}>{"正常"}</span>
                } else if (text === "created") {
                    return <span style={{color: "#ce8502"}}>{"导入异常批次"}</span>
                } else if (text === "disable") {
                    return <span style={{color: "red"}}>{"禁用"}</span>
                } else {
                    return <span style={{color: "gray"}}>{"未知异常批次"}</span>
                }

            }
        },
        {
            title: '免费套餐组',
            dataIndex: 'giftPackageGroupName',
            key: 'giftPackageGroupName',
        },
        {
            title: '付费套餐组',
            dataIndex: 'payPackageGroupName',
            key: 'payPackageGroupName',
        },
        {
            title: '创建时间',
            dataIndex: 'createTime',
            key: 'createTime',
            render: (text: any, record: any) => {
                if (text) {
                    return dayjs.unix(text).format('YYYY-MM-DD HH:mm:ss')
                }
                return text
            }
        },
        {
            title: '操作',
            dataIndex: 'operation',
            key: 'operation',
            render: (text: any, record: any) => {
                return <Space size={'large'}>
                    <Button type={"link"}
                            onClick={() => {
                                setSelectedBatch(record);
                                setImportDeviceOpen(true);
                            }}>新增设备</Button>
                    <Button type={"link"} onClick={() => {
                        setSelectedBatch(record);
                        setShowFormModal(true);
                    }}>编辑</Button>
                    <Popconfirm title={"确定删除该批次吗？"} description={"该操作将无法回退！"} okText={"确定"}
                                cancelText={"取消"}
                                onConfirm={() => _deleteBatch(record)}>
                        <Button type={"link"}>删除</Button>
                    </Popconfirm>
                </Space>
            }
        }
    ]

    return <div>
        {contextHolder}
        {noticeContextHolder}
        <Row>
            <Col span={24} style={{textAlign: 'right'}}>
                <Form.Item>
                    <Space size={'small'}>
                        <Button type={'primary'} onClick={() => setImportDeviceOpen(true)}
                                icon={<ImportOutlined/>}>创建批次并导入设备</Button>
                    </Space>
                </Form.Item>
            </Col>
        </Row>
        <Table columns={columns} dataSource={batchList} pagination={false} rowKey="id"/>
        <Modal title="请再次确定，该操作无法回退！！" open={deleteConfirm}
               okText={"确定"}
               cancelText={"取消"}
               onCancel={() => {
                   setDeleteConfirm(false);
               }}
               onOk={() => {
                   _doDeleteBatch();
               }}>
            <p><span style={{fontWeight: 'bold'}}>{selectedBatch?.batchName}</span> 下所有设备关联的信息都将被删除，包括：
            </p>
            <p>该批次所有的<span style={{color: 'red'}}>订单信息</span></p>
            <p>该批次所有的<span style={{color: 'red'}}>激活记录</span></p>
            <p>该批次所有的<span style={{color: 'red'}}>操作历史</span></p>
        </Modal>


        <Modal open={showFormModal}
               closable={true}
               footer={null}
               destroyOnClose={true}
               maskClosable={false}
               onCancel={formClose}>
            <BatchEditForm
                onSuccess={() => {
                    msgApi.success("保存成功").then();
                    setShowFormModal(false);
                    _search();
                }}
                batch={selectedBatch}
            />
        </Modal>


        <Modal open={importDeviceOpen}
               closable={true}
               footer={null}
               destroyOnClose={true}
               maskClosable={false}
               onCancel={onImportModalClose}>
            <ImportDeviceForm onImportSuccess={onImportSuccess} deviceBatch={selectedBatch}/>
        </Modal>
    </div>

}
