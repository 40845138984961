import {Button, Form, Input, message, Select} from "antd";
import {useEffect, useState} from "react";
import {packageService} from "../../service/package";
import {deviceService} from "../../service/device";

export interface IDeviceOperationProps {
    onSuccess: () => void;
    batch: any;
}

export const BatchEditForm = (props: IDeviceOperationProps) => {

    const {onSuccess, batch} = props;
    const [msgApi, contextHolder] = message.useMessage()
    const [form] = Form.useForm();
    const [giftPackageList, setGiftPackageList] = useState<any[]>([])
    const [buyPackageGroupList, setBuyPackageGroupList] = useState<any[]>([])

    useEffect(() => {
        // console.log("batch", batch);
        init();
    }, []);


    const init = () => {
        // 获取所有可用的套餐组
        packageService.enablePackageGroupList()
            .then(res => {
                // console.log("res", res);
                const responseMsg = res.data;
                if (responseMsg.status === 0) {
                    const giftList: any[] = [];
                    const buyList: any[] = [];
                    responseMsg.data.forEach((v: any) => {
                        if (v.packageGroupType === "buy") {
                            buyList.push({value: v.id, label: v.title});
                        } else {
                            giftList.push({value: v.id, label: v.title});
                        }
                    })
                    setGiftPackageList(giftList);
                    setBuyPackageGroupList(buyList);
                } else {
                    msgApi.error(responseMsg.msg).then();
                }
            })
            .catch(err => {
                msgApi.error("发生未知错误").then();
            })
    }

    const onFinish = (values: any) => {
        console.log("onFinish", values);
        deviceService.updateDeviceBatch(values)
            .then(res => {
                const responseMsg = res.data;
                if (responseMsg.status === 0) {
                    if (onSuccess) {
                        onSuccess();
                    }
                } else {
                    msgApi.error(responseMsg.msg).then();
                }

            })
            .catch(err => {
                msgApi.error("发生未知错误").then();
            })
    }

    useEffect(() => {
        fillForm(batch);
    }, [giftPackageList, buyPackageGroupList, batch]);

    const fillForm = (deviceBatch: any) => {
        form.setFieldsValue({
            id: deviceBatch.id,
            batchName: deviceBatch.batchName,
            giftPackageGroupId: deviceBatch.giftPackageGroupId,
            payPackageGroupId: deviceBatch.payPackageGroupId
        })
    }

    return <div style={{margin: "20px 40px 0 0"}}>
        {contextHolder}
        <Form form={form} onFinish={onFinish}>
            <Form.Item style={{display: "none"}} labelCol={{span: 8}} wrapperCol={{span: 16}} label={"批次ID"}
                       name={"id"}>
                <Input/>
            </Form.Item>
            <Form.Item labelCol={{span: 8}} wrapperCol={{span: 16}} label={"批次名称"} name={"batchName"}
                       rules={[{required: true, message: '批次名称不能为空!'}]}
            >
                <Input/>
            </Form.Item>

            <Form.Item labelCol={{span: 8}} wrapperCol={{span: 16}} label={"免费套餐组"} name={"giftPackageGroupId"}>
                <Select
                    options={giftPackageList}
                    allowClear={true}
                />
            </Form.Item>

            <Form.Item labelCol={{span: 8}}
                       wrapperCol={{span: 16}}
                       label={"付费套餐组"}
                       name={"payPackageGroupId"}
                       rules={[{required: true, message: '付费套餐组不能为空!'}]}
            >
                <Select
                    options={buyPackageGroupList}
                />
            </Form.Item>


            <Form.Item wrapperCol={{offset: 8, span: 16}}>
                <Button type={"primary"} htmlType={"submit"}>保存</Button>
            </Form.Item>
        </Form>

    </div>
}
