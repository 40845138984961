import {httpService} from "../http-service";

export class DeviceService {

    /**
     * 获取设备列表
     * @param param
     */
    getDeviceList(param: {
        pageNum: number,
        pageSize: number,
        batchId?: number,
        deviceId?: number,
        deviceTypeId?: number,
        deviceStatus?: string,
    }) {
        return httpService.post("/device/list", {...param, pageNum: param.pageNum - 1});
    }


    /**
     * 获取所有的设备类型列表
     */
    getDeviceTypeList() {
        return httpService.post("/device/type/list/all", {});
    }

    getEnableDeviceTypeList() {
        return httpService.post("/device/type/list/enable", {});
    }

    /**
     * 获取设备批次列表
     * @param param
     */
    getDeviceBatchList(param: {
        id?: number,
        batchName?: string,
    }) {
        return httpService.post("/device/batch/list", param)
    }

    getEnableDeviceBatchList() {
        return httpService.post("/device/batch/list/enable", {})
    }

    /**
     * 创建设备批次
     * @param param
     */
    createDeviceBatch(param: {
        batchName: string;
        deviceTypeId: number;
        payPackageGroupId: number;
        giftPackageGroupId?: number;
    }) {
        return httpService.post("/device/batch/create", param);
    }

    /**
     * 获取设备详情
     * @param param
     */
    getDeviceDetail(param: {
        deviceId: number,
    }) {

    }

    /**
     * 更改设备信息
     * @param param
     */
    // updateDevice(param: any){
    //     return httpService.post("/device/update", param);
    // }

    /**
     * 更新设备批次号
     * @param param
     */
    updateDeviceBatchId(param: any) {
        return httpService.post("/device/update/batchId", param);
    }

    /**
     * 将设备手动停机
     * @param deviceId
     */
    deviceShutdown(deviceId: string) {
        return httpService.post("/device/shutdown", {deviceId});
    }

    importDevice(batchId: number, _file: any) {
        let formData = new FormData();
        formData.append("file", _file);
        return httpService.post(`/device/import?batchId=${batchId}`, formData, {
            "Content-Type": "multipart/form-data"
        });
    }

    deleteDeviceBatch(selectedBatch: any) {
        return httpService.post("/device/batch/delete", selectedBatch);
    }

    updateDeviceBatch(param: any) {
        return httpService.post("/device/batch/update", param);
    }


    /**
     * 单个删除设备
     * @param param
     */
    deleteDevice(param: {
        deviceId: string,
        [key: string]: any
    }) {
        return httpService.post("/device/delete", param);
    }

    /**
     * 批量删除设备
     * @param param
     */
    deleteDeviceList(param: {
        batchId?: number,
        deviceId?: number,
        deviceTypeId?: number,
        deviceStatus?: string,
    }) {
        return httpService.post("/device/delete/list", param);

    }

    /**
     * 手动复机
     * @param param
     */
    activeManual(param: {
        batchId?: number,
        deviceId?: number,
        deviceTypeId?: number,
        deviceStatus?: string,
    }) {
        return httpService.post("/device/active/manual", param);
    }

    /**
     * 导出设备列表
     * @param params
     */
    exportDeviceList(params: any) {
        return httpService.post("/device/list/export", params,{}, "blob");
    }


}

export const deviceService = new DeviceService();
