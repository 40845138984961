import {httpService} from "../http-service";

export class ConfigMonitorService {

    getMchList() {
        return httpService.postOther("wx/config/mch/list", null, {}, process.env.REACT_APP_MONITOR_BASEURL)
    }

    getMpList() {
        return httpService.postOther("wx/config/mp/list", null, {}, process.env.REACT_APP_MONITOR_BASEURL)
    }

    addOrUpdate(mch: any) {
        if (mch && mch.id) {
            return httpService.postOther("wx/config/mch/update", mch, {}, process.env.REACT_APP_MONITOR_BASEURL)
        } else {
            return httpService.postOther("wx/config/mch/add", mch, {}, process.env.REACT_APP_MONITOR_BASEURL)
        }

    }

    getMpMchRelList() {
        return httpService.postOther("wx/config/mp/mch/rel/list", null, {}, process.env.REACT_APP_MONITOR_BASEURL)
    }

    updateRel(rel: any) {
        return httpService.postOther("wx/config/mp/mch/rel/update", rel, {}, process.env.REACT_APP_MONITOR_BASEURL)
    }

    /**
     * 卡列表
     * @param param
     */
    cardList(param: any) {
        return httpService.postOther("admin/card/list", param, {}, process.env.REACT_APP_MONITOR_BASEURL)
    }

    /**
     * 更新卡
     */
    updateCard(param: any){
        return httpService.postOther("admin/card/update", param, {}, process.env.REACT_APP_MONITOR_BASEURL);
    }

    operatorList() {
        return httpService.postOther("admin/operator/list", {}, {}, process.env.REACT_APP_MONITOR_BASEURL)
    }

    importCard(formData: any, header: any) {
        return httpService.postOther("admin/card/import", formData, header, process.env.REACT_APP_MONITOR_BASEURL)
    }

    listCardLog(param: any) {
        return httpService.postOther("admin/card/order/log/list", param, {}, process.env.REACT_APP_MONITOR_BASEURL)
    }

    cardOperatorList() {
        return httpService.postOther("admin/card/operator/list", {}, {}, process.env.REACT_APP_MONITOR_BASEURL)
    }

    commonConfigList() {
        return httpService.postOther("admin/package/config/common/list", {}, {}, process.env.REACT_APP_MONITOR_BASEURL)
    }

    updateCommonConfig(param: any) {
        return httpService.postOther("admin/package/config/common/update", param, {}, process.env.REACT_APP_MONITOR_BASEURL)
    }

    packageConfigList() {
        return httpService.postOther("/package/config/list", {}, {}, process.env.REACT_APP_MONITOR_BASEURL)
    }

    updatePackageConfig(param: any) {
        return httpService.postOther("/package/config/update", param, {}, process.env.REACT_APP_MONITOR_BASEURL)
    }

    addPackageConfig(param: any) {
        return httpService.postOther("admin/package/config/add", param, {}, process.env.REACT_APP_MONITOR_BASEURL)
    }


}

export const configMonitorService = new ConfigMonitorService();
