import {httpService} from "../http-service";

export class PackageService {
    enablePackageGroupList(groupType?: "buy" | "gift") {
        let url = "/package/group/list/enable";
        if (groupType != null) {
            url = url + `?packageGroupType=${groupType}`;
        }
        return httpService.post(url, {});
    }

    allPackageGroupList() {
        return httpService.post("/package/group/list/all", {});
    }

    listPackageItem(param: {
        packageGroupId?: number;
        packageName?: string;
        packageStatus?: string;
        pageNum: number;
        pageSize: number;
    }) {
        return httpService.post("/package/list", param);
    }

    createPackageGroup(param: {
        title: string;
        packageGroupType: string;
        description: string;
    }) {
        return httpService.post("/package/group/add", param);
    }

    updatePackageGroup(param: any){
        return httpService.post("/package/group/update", param);
    }

    createPackageItem(param: {
        packageGroupId: number;
        packageName: string;
        packagePrice: number;
        packagePayPrice: number;
        packageStatus: string;
        packageDesc: string;
        packageTime: string;
    }) {
        return httpService.post("/package/add", param);
    }


    updatePackageItem(param: {
        id: number,
        packageGroupId: number;
        packageName: string;
        packageDesc: string;
        packageIcon?: string;
        packageStatus: string;
        packagePrice: number;
        packagePayPrice: number;
        packageOrder?: number;
        packageTime: string;
    }) {
        return httpService.post("/package/update", param);
    }

    deletePackageItem(param: { id: number }) {
        return httpService.post("/package/delete", param);
    }

    deleteGroup(packageGroup: { id: number, [key: string]: any }) {
        return httpService.post("/package/group/delete", packageGroup);
    }
}

export const packageService = new PackageService();
