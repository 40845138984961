import {Button, Form, message, Modal, Select, SelectProps, Space, Table} from "antd";
import React, {useEffect, useState} from "react";
import dayjs from "dayjs";
import {configMonitorService} from "../../service";

/**
 * 监控系统商户号列表
 * @constructor
 */
export const CMWxMpMchRel = () => {

    const [msgApi, messageCtxHolder] = message.useMessage()
    const [mpMchList, setMpMchList] = useState([]);
    const [showModal, setShowModal] = useState(false);
    const [selectedRel, setSelectedRel] = useState<any>(null);
    const [options, setOptions] = useState<any>([]);
    const [wxMchList, setWxMchList] = useState([]);
    const [form] = Form.useForm();

    useEffect(() => {
        getMpMchRelList();
        getWxMchList();
    }, []);



    const getWxMchList = () => {
        configMonitorService.getMchList()
            .then(res => {
                // console.log(res.data);
                setWxMchList(res.data);
                const _options = [];
                for (let i = 0; i < res.data.length; i++) {
                    const mchItem = res.data[i];
                    _options.push({
                        label: mchItem.mchName,
                        value: mchItem.mchId
                    })
                }
                setOptions(_options);
            })
            .catch(err => {
                // console.error(err);
                msgApi.error("发生错误");
            })
    }
    const getMpMchRelList = () => {
        configMonitorService.getMpMchRelList()
            .then(res => {
                // console.log(res.data);
                setMpMchList(res.data);
            })
            .catch(err => {
                // console.log(err);
                msgApi.error("发生错误");
            })
    }


    const handleSubmit = (values: any) => {
        // console.log(values);
        // 从mchList中找到商户号对应的记录
        const mchInfo: any = wxMchList.find((item: any) => item.mchId === values.mchId);
        const _tempRel = {...selectedRel};
        _tempRel.mchId = values.mchId;
        _tempRel.mchName = mchInfo?.mchName;
        // console.log("tempRel", _tempRel);
        configMonitorService.updateRel(_tempRel)
            .then(res=>{
                msgApi.success("操作成功");
                getMpMchRelList();
                setShowModal(false);
            })
            .catch(err=>{
                msgApi.error("操作失败");
            })

    }


    const columns = [
        {
            key: "appid",
            dataIndex: "appid",
            title: "AppId"
        },
        {
            key: "mpName",
            dataIndex: "mpName",
            title: "小程序/公众号名称",
        },
        {
            key: "mchId",
            dataIndex: "mchId",
            title: "商户号",
        },
        {
            key: "mchName",
            dataIndex: "mchName",
            title: "商户名称",
        },
        {
            key: "createTime",
            dataIndex: "createTime",
            title: "创建时间",
            render: (text: any) => {
                if (text) {
                    return dayjs.unix(text).format('YYYY-MM-DD HH:mm:ss')
                }
                return text
            }
        },
        {
            key: "updateTime",
            dataIndex: "updateTime",
            title: "更新时间",
            render: (text: any) => {
                if (text) {
                    return dayjs.unix(text).format('YYYY-MM-DD HH:mm:ss')
                }
                return text
            }
        },
        {
            key: 'operate',
            dataIndex: 'operate',
            title: '操作',
            render: (text: any, record: any) => {
                return <Space size={'small'}>
                    <Button type={'link'} size={"small"} onClick={() => {
                        setSelectedRel(record);
                        setShowModal(true);
                        form.setFieldsValue({
                            mchId: record.mchId
                        })
                    }}>更改关联商户</Button>
                </Space>
            }
        }
    ];

    return <div>
        {messageCtxHolder}
        <Table rowKey={"id"}
               columns={columns}
               dataSource={mpMchList}
        />

        <Modal open={showModal}
               closable={true}
               footer={null}
               destroyOnClose={false}
               maskClosable={true}
               onCancel={() => setShowModal(false)}>
            <Form labelCol={{span: 8}}
                  wrapperCol={{span: 16}}
                  name={'basic'}
                  form={form}
                  onFinish={handleSubmit}>
                <Form.Item name={"mchId"}
                           label={"选择新的商户号"}
                           rules={[{required: true, message: '商户号不能为空!'}]}>
                    <Select style={{width: "180px"}} options={options}/>
                </Form.Item>

                <Form.Item wrapperCol={{offset: 8, span: 16}}>
                    <Button type="primary" htmlType="submit" loading={false}>
                        保 存
                    </Button>
                </Form.Item>
            </Form>
        </Modal>
    </div>
}
