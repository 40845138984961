export const defaultMenus = [
    {
        "key": "1",
        "menuName": "数据日报",
        "menuType": "page",
        "parentId": null,
        "status": "enable",
        "url": "/data",
        "icon": "PieChartOutlined",
        "checked": null,
        "childList": null
    },
    {
        "key": "10",
        "menuName": "设备管理",
        "menuType": "menu",
        "parentId": null,
        "url":"/device",
        "status": "enable",
        "icon": "DesktopOutlined",
        "checked": null,
        "childList": [
            {
                "key": "101",
                "menuName": "设备批次",
                "menuType": "page",
                "parentId": "10",
                "status": "enable",
                "url": "/device/batch/list",
                "icon": null,
                "checked": null,
                "childList": null
            },
            {
                "key": "102",
                "menuName": "设备列表",
                "menuType": "page",
                "parentId": "10",
                "status": "enable",
                "url": "/device/list",
                "icon": null,
                "checked": null,
                "childList": null
            },
        ]
    },
    {
        "key": "20",
        "menuName": "套餐管理",
        "menuType": "menu",
        "parentId": null,
        "url":"/package",
        "status": "enable",
        "icon": "PayCircleOutlined",
        "checked": null,
        "childList": [
            {
                "key": "201",
                "menuName": "套餐组列表",
                "menuType": "page",
                "parentId": "10",
                "status": "enable",
                "url": "/package/group/list",
                "icon": null,
                "checked": null,
                "childList": null
            },
            {
                "key": "202",
                "menuName": "套餐列表",
                "menuType": "page",
                "parentId": "10",
                "status": "enable",
                "url": "/package/list",
                "icon": null,
                "checked": null,
                "childList": null
            },

        ]
    },
    {
        "key": "30",
        "menuName": "订单管理",
        "menuType": "menu",
        "parentId": null,
        "url":"/order",
        "status": "enable",
        "icon": "SnippetsOutlined",
        "checked": null,
        "childList": [
            {
                "key": "301",
                "menuName": "订单列表",
                "menuType": "page",
                "parentId": "30",
                "status": "enable",
                "url": "/order/list",
                "icon": null,
                "checked": null,
                "childList": null
            },
        ]
    },
    {
        "key": "40",
        "menuName": "配置管理",
        "menuType": "menu",
        "parentId": null,
        "url":"/config",
        "status": "enable",
        "icon": "SnippetsOutlined",
        "checked": null,
        "childList": [
            {
                "key": "401",
                "menuName": "商户号列表",
                "menuType": "page",
                "parentId": "40",
                "status": "enable",
                "url": "/config/wx/list",
                "icon": null,
                "checked": null,
                "childList": null
            },
        ]
    },
    {
        "key": "50",
        "menuName": "监控系统配置",
        "menuType": "menu",
        "parentId": null,
        "url":"/config-monitor",
        "status": "enable",
        "icon": "SnippetsOutlined",
        "checked": null,
        "childList": [
            {
                "key": "501",
                "menuName": "关联管理",
                "menuType": "page",
                "parentId": "50",
                "status": "enable",
                "url": "/config-monitor/mp/mch/rel",
                "icon": null,
                "checked": null,
                "childList": null
            },
            {
                "key": "502",
                "menuName": "商户号列表",
                "menuType": "page",
                "parentId": "50",
                "status": "enable",
                "url": "/config-monitor/mch/list",
                "icon": null,
                "checked": null,
                "childList": null
            },
            {
                "key": "503",
                "menuName": "卡通道规则列表",
                "menuType": "page",
                "parentId": "50",
                "status": "enable",
                "url": "/config-monitor/card/list",
                "icon": null,
                "checked": null,
                "childList": null
            },
            {
                "key": "504",
                "menuName": "卡通道套餐配置",
                "menuType": "page",
                "parentId": "50",
                "status": "enable",
                "url": "/config-monitor/package/config",
                "icon": null,
                "checked": null,
                "childList": null
            },
            {
                "key": "505",
                "menuName": "卡通道通用配置",
                "menuType": "page",
                "parentId": "50",
                "status": "enable",
                "url": "/config-monitor/package/common/config",
                "icon": null,
                "checked": null,
                "childList": null
            },
            {
                "key": "506",
                "menuName": "监控失败订单查询",
                "menuType": "page",
                "parentId": "50",
                "status": "enable",
                "url": "/config-monitor/card/order/log",
                "icon": null,
                "checked": null,
                "childList": null
            },

        ]
    },
    // {
    //     "id": "636926455aeedf21dc4068be",
    //     "menuName": "财务管理",
    //     "menuType": "menu",
    //     "parentId": null,
    //     "status": "enable",
    //     "url": "/finance",
    //     "icon": "MoneyCollectOutlined",
    //     "checked": null,
    //     "childList": [
    //         {
    //             "id": "6369b1f35aeedf21dc406910",
    //             "menuName": "订单管理",
    //             "menuType": "page",
    //             "parentId": null,
    //             "status": "enable",
    //             "url": "/order-list",
    //             "icon": null,
    //             "checked": null,
    //             "childList": null
    //         }
    //     ]
    // },
    // {
    //     "id": "6369266c5aeedf21dc4068c0",
    //     "menuName": "后台管理",
    //     "menuType": "menu",
    //     "parentId": null,
    //     "status": "enable",
    //     "url": "/admin",
    //     "icon": "SettingOutlined",
    //     "checked": null,
    //     "childList": [
    //         {
    //             "id": "6369b23a5aeedf21dc406913",
    //             "menuName": "用户管理",
    //             "menuType": "page",
    //             "parentId": null,
    //             "status": "enable",
    //             "url": "/user-manage",
    //             "icon": null,
    //             "checked": null,
    //             "childList": null
    //         },
    //         {
    //             "id": "6369b2405aeedf21dc406914",
    //             "menuName": "角色管理",
    //             "menuType": "page",
    //             "parentId": null,
    //             "status": "enable",
    //             "url": "/role-manage",
    //             "icon": null,
    //             "checked": null,
    //             "childList": null
    //         }
    //     ]
    // }
]
